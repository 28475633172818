import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SsoIntegrationComponent } from "./sso-integration.component";
import { PipesModule } from "../../pipes/pipes.module";
import { ReactiveFormsModule } from "@angular/forms";
import { InputModule } from "../../components/inputs/input.module";
import { RegasComponentsSelectModule } from "@regas/components";

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        ReactiveFormsModule,
        InputModule,
        RegasComponentsSelectModule
    ],
  declarations: [SsoIntegrationComponent],
  exports: [SsoIntegrationComponent]
})
export class SsoIntegrationModule {}
