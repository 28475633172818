<regas-client-alert
  *ngIf="error?.message !== 'error.invalidCredentials'"
  [error]="error"
></regas-client-alert>
<div class="client-credentials-wrapper">
  <form [formGroup]="form" name="credentials" role="form">
    <regas-text-input
      formControlName="email"
      id="email"
      name="email"
      autocomplete="email"
      (input)="input.emit()"
      (keyup.enter)="onSubmit()"
      [required]="true"
      [externalError]="displayExternalError(error)"
      [validateInput]="isEmailFormatValid"
      label="{{ 'email' | translate: translations | upperFirst }}"
      requiredLabel="{{
        'emailRequired' | translate: translations | upperFirst
      }}"
      incorrectLabel="{{
        'incorrectCredentials' | translate: translations | upperFirst
      }}"
      invalidLabel=" {{
        'invalidEmailFormat' | translate: translations | upperFirst
      }}"
      placeholder=" {{ 'typeYourEmail' | translate: translations }}"
    >
    </regas-text-input>
    <regas-password-input
      formControlName="password"
      id="password"
      name="password"
      autocomplete="current-password"
      [maxLength]="48"
      [autoFocus]="false"
      (input)="input.emit()"
      (keyup.enter)="onSubmit()"
      [required]="true"
      [externalError]="displayExternalError(error)"
      label="{{ 'password' | translate: translations | upperFirst }}"
      requiredLabel="{{
        'passwordRequired' | translate: translations | upperFirst
      }}"
      incorrectLabel="{{
        'incorrectCredentials' | translate: translations | upperFirst
      }}"
      placeholder=" {{ 'typeYourPassword' | translate: translations }}"
    >
    </regas-password-input>
    <regas-button
      [loading]="state?.loading || loading"
      [formGroup]="form"
      buttonText="{{ 'logIn' | translate: translations }}"
      (submitForm)="onSubmit()"
    >
    </regas-button>
  </form>
  <div>
    <div class="d-flex justify-content-center login-router-link-wrapper">
      <a routerLink="./password-recovery" class="login-router-link">
        {{ 'forgotPassword' | translate: translations | upperFirst }}
      </a>
    </div>
  </div>
</div>
