<div class="phone-migration-container">
  <regas-layout-header
    imageSrc="assets/images/login/data-protected.svg"
    [upperBoldStyle]="false"
    upperText="{{ 'missingPhoneInfo' | translate: translations | upperFirst }}"
  ></regas-layout-header>
  <form [formGroup]="formGroup">
    <regas-client-phone-input
      formControlName="phoneNumber"
      id="phoneNumber"
      name="phoneNumber"
      [autocomplete]="false"
      [requiredIndicator]="true"
      (keyup.enter)="onSubmit()"
      [required]="true"
      label="{{
        'registration.label.phoneNumber' | translate: translations | upperFirst
      }}"
      placeholder="{{
        'registration.placeholder.phoneNumber'
          | translate: translations
          | upperFirst
      }}"
      requiredLabel="{{
        'registration.error.phoneNumberRequired'
          | translate: translations
          | upperFirst
      }}"
      invalidLabel="{{
        'registration.error.phoneNumberInvalidFormat'
          | translate: translations
          | upperFirst
      }}"
    >
    </regas-client-phone-input>
    <regas-button
      [formGroup]="formGroup"
      (submitForm)="onSubmit()"
      buttonText="{{ 'next' | translate: translations }}"
    >
    </regas-button>
  </form>
</div>
