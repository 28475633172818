import { Component, Input } from '@angular/core';
import { translations } from '../../../translations';

@Component({
  selector: 'regas-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent {
  @Input()
  header = 'notFoundPage.header';
  @Input()
  content = 'notFoundPage.content';
  @Input()
  imageUrl = 'assets/images/login/404.svg';
  @Input()
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  translations: any = translations;
}
