import { Injectable, OnDestroy } from '@angular/core';
import { CookieService, SettingsService } from '@regas/shared';
import { catchError, takeUntil } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { of, Subject } from 'rxjs';
import { UserDetailsService } from '../../user-details/user-details.service';
import { UrlResolver } from '../../../utils/url-resolver/url-resolver';
import { HttpFactory } from '../../../utils/http-factory/http-factory';
import { LoginService } from '../login.service';
import { WindowNavigationService } from '../../navigation/navigation.service';
import { SurveyRequestDialogService } from '../../../survey-request-dialog/survey-request-dialog.service';
import { SurveyData } from '../../../survey-request-dialog/model/survey-data';

@Injectable({
  providedIn: 'root',
})
export class LoginSuccessHandlerService implements OnDestroy {
  private readonly endSubscription$ = new Subject<boolean>();

  constructor(
    private readonly cookieService: CookieService,
    private readonly loginService: LoginService,
    private readonly userDetailsService: UserDetailsService,
    private readonly settingsService: SettingsService,
    private readonly windowNavigationService: WindowNavigationService,
    private readonly surveyRequestDialogService: SurveyRequestDialogService,
  ) {}

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  // todo - improve the way of handling redirections
  onSuccess(
    accessToken: string,
    refreshToken: string,
    encodedGoToUrl: string | null,
    surveysEnabled = true,
  ): void {
    const gotoUrl = UrlResolver.getUrl(encodedGoToUrl || '');

    this.setCookies(accessToken, refreshToken);

    if (surveysEnabled) {
      this.surveyRequestDialogService
        .getSurveyData(accessToken)
        .pipe(catchError(() => of(null)))
        .subscribe((surveyData: SurveyData) => {
          if (!surveyData) {
            this.redirectAfterLogin(accessToken, gotoUrl);
          } else {
            this.surveyRequestDialogService.showDialog(surveyData, () =>
              this.redirectAfterLogin(accessToken, gotoUrl),
            );
          }
        });
    } else {
      this.redirectAfterLogin(accessToken, gotoUrl);
    }
  }

  private redirectAfterLogin(accessToken: string, gotoUrl?: URL): void {
    if (this.isDefaultUrlRedirection(gotoUrl)) {
      this.goToDefaultUrl(accessToken);
      return;
    }

    this.loginService
      .checkUrlWhiteList(gotoUrl as URL, accessToken)
      .pipe(takeUntil(this.endSubscription$))
      .subscribe({
        next: (success: boolean) => {
          success
            ? this.windowNavigationService.goTo((gotoUrl as URL).href)
            : this.goToDefaultUrl(accessToken);
        },
        error: () => {
          this.goToDefaultUrl(accessToken);
        },
      });
  }

  private async goToDefaultUrl(accessToken: string): Promise<void> {
    try {
      const authHeader = HttpFactory.getHeaders(
        'Authorization',
        'Bearer ' + accessToken,
      );
      const { role } = await this.userDetailsService.getPersonData(authHeader);
      const redirectionUrl = await this.userDetailsService.getRedirectUrl(
        authHeader,
        role,
      );
      const isRedirectionUrlValid = UrlResolver.getUrl(redirectionUrl || '');
      if (!isRedirectionUrlValid) {
        throw Error('Cannot parse redirection url ' + redirectionUrl);
      }
      this.windowNavigationService.goTo(redirectionUrl);
    } catch (exception) {
      this.windowNavigationService.goTo(window.location.origin + '/redirect');
    }
  }

  private isDefaultUrlRedirection(gotoUrl: URL | undefined): boolean {
    const origin = this.windowNavigationService.getLocation().origin;
    return (
      !gotoUrl ||
      UrlResolver.isUrlToOlympus(origin, gotoUrl) ||
      UrlResolver.isUrlToLogout(origin, gotoUrl)
    );
  }

  private setCookies(accessToken: string, refreshToken: string): void {
    const {
      xsrf,
      access,
      refresh,
    } = this.settingsService.getAuthenticationCookies();

    this.cookieService.set({
      ...xsrf,
      value: uuid(),
    });

    this.cookieService.set({
      ...access,
      value: accessToken,
    });

    this.cookieService.set({
      ...refresh,
      value: refreshToken,
    });

    this.cookieService.set({
      value: 'display',
      name: 'notification',
      path: '/',
      domain: window.location.hostname.includes('.regas.nl')
        ? '.regas.nl'
        : '.regas.be',
      expire: 24,
      secure: true,
    });
  }
}
