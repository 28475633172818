import {SsoIntegration} from '../../models/SsoIntegration';
import {SsoIntegrationDto} from '../../models/SsoIntegrationDto';

export class SsoIntegrationFactory {
  public static map(
    ssoIntegrationDTOs: SsoIntegrationDto[],
    apiUrl: string,
    substitution: string,
    goto: string | null,
  ): SsoIntegration[] {
    return ssoIntegrationDTOs.map((ssoIntegrationDto: SsoIntegrationDto) => ({
      displayName: ssoIntegrationDto.displayName,
      loginUrl: SsoIntegrationFactory.buildLoginUrl(
        apiUrl,
        ssoIntegrationDto.registrationId,
        substitution,
        goto,
      ),
    }));
  }

  private static buildLoginUrl(
    rootUrl: string,
    registrationId: string,
    substitution: string,
    goto: string | null,
  ): string {
    const url = `${rootUrl}?id=${registrationId}&substitution=${substitution}`;
    return goto ? `${url}&goto=${goto}` : url;
  }
}
