import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ClientRegistrationService } from '../../services/registration/client/client-registration.service';
import { RegistrationState } from '../../models/interface/registration-state.interface';
import { EventAlertService } from '../../services/event/event-alert.service';
import { ErrorMessageFormatter } from '../../utils/attempts-message-formatter/error-message-formatter';
import { translations } from '../../translations';
import { RegistrationData } from '../../models/interface/registration-data.interface';
import { LanguageService } from '../../services/language/language.service';

@Component({
  selector: 'regas-employee-registration',
  templateUrl: './employee-registration.component.html',
})
export class EmployeeRegistrationComponent implements OnInit, OnDestroy {
  protected readonly translations = translations;
  private readonly endSubscription$ = new Subject<boolean>();
  state$: Observable<RegistrationState>;
  email: string;
  validationCode: string;
  organisation: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly registrationService: ClientRegistrationService,
    private readonly eventAlertService: EventAlertService,
    private readonly languageService: LanguageService,
  ) {}

  ngOnInit(): void {
    this.initDataAndValidate();
    this.initState();
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  onRegistration(registrationData: RegistrationData): void {
    this.eventAlertService.clearMessage();
    this.state$
      .pipe(
        take(1),
        switchMap(state =>
          this.registrationService
            .registerEmployee({
              ...state,
              data: {
                ...registrationData,
                validationCode: this.validationCode,
                emailAddress: this.email,
                language: this.languageService.getLanguage(),
              },
            })
            .pipe(
              catchError((errorResponse: HttpErrorResponse) => {
                this.registrationService.handleError(
                  ErrorMessageFormatter.produceInvalidInputMessage(
                    errorResponse,
                    'error.invalidVerificationCode',
                    'registration.error.unexpected',
                  ),
                );
                return EMPTY;
              }),
            ),
        ),
      )
      .subscribe(() => {
        this.eventAlertService.setMessage({
          message: 'success.registrationCompleted',
          type: 'success',
        });
        this.router.navigate([this.organisation]);
      });
  }

  private initDataAndValidate(): void {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this.endSubscription$),
        tap(queryParams => {
          this.email = queryParams?.email || '';
          this.validationCode = queryParams?.validationcode || '';
        }),
        switchMap(() => this.activatedRoute.params),
        tap(params => {
          this.organisation = params['substitution'];
        }),
        switchMap(() => this.fillMissingEmailIfNecessary()),
      )
      .subscribe();
  }

  private fillMissingEmailIfNecessary(): Observable<object> {
    if (this.email === '') {
      this.registrationService.switchToActivationFlow();
      return this.registrationService
        .getEmailForActivationRequest(this.validationCode)
        .pipe(
          take(1),
          switchMap(response => {
            this.email = response.emailAddress;
            return EMPTY;
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.registrationService.handleError(errorResponse);
            return EMPTY;
          }),
        );
    } else {
      return this.submitValidation();
    }
  }

  private submitValidation(): Observable<object> {
    return this.registrationService
      .validateInvitation(
        this.email,
        this.validationCode,
        this.organisation?.toLowerCase(),
      )
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          this.registrationService.handleError(errorResponse);
          return EMPTY;
        }),
      );
  }

  private initState(): void {
    this.state$ = this.registrationService
      .getState()
      .pipe(takeUntil(this.endSubscription$));
  }
}
