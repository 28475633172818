import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PasswordStrength } from '../../../../../models/enum/password-strength.enum';
import { translations } from '../../../../../translations';

@Component({
  selector: 'regas-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent implements OnChanges {
  private readonly BASE_BAR_CSS = 'base-bar';
  private readonly WEAK_BAR_CSS = 'weak-bar';
  private readonly FAIR_BAR_CSS = 'fair-bar';
  private readonly GOOD_BAR_CSS = 'good-bar';
  private readonly LAST_BAR_CSS = 'last-bar';

  @Output() passwordStatus = new EventEmitter();
  @Input() password: string;

  translations = translations;
  barOneCss: string;
  barTwoCss: string;
  barThreeCss: string;
  barFourCss: string;

  text: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.password) {
      this.updateBar();
    }
  }

  private handleBarsCss(passwordStrength: PasswordStrength): void {
    switch (passwordStrength) {
      case PasswordStrength.FAIR:
        this.barOneCss = this.FAIR_BAR_CSS;
        this.barTwoCss = this.FAIR_BAR_CSS;
        this.barThreeCss = this.BASE_BAR_CSS;
        this.barFourCss = `${this.BASE_BAR_CSS} ${this.LAST_BAR_CSS}`;
        break;
      case PasswordStrength.GOOD:
        this.barOneCss = this.GOOD_BAR_CSS;
        this.barTwoCss = this.GOOD_BAR_CSS;
        this.barThreeCss = this.GOOD_BAR_CSS;
        this.barFourCss = `${this.BASE_BAR_CSS} ${this.LAST_BAR_CSS}`;
        break;
      case PasswordStrength.STRONG:
        this.barOneCss = this.GOOD_BAR_CSS;
        this.barTwoCss = this.GOOD_BAR_CSS;
        this.barThreeCss = this.GOOD_BAR_CSS;
        this.barFourCss = `${this.GOOD_BAR_CSS} ${this.LAST_BAR_CSS}`;
        break;
      default:
        this.barOneCss = this.WEAK_BAR_CSS;
        this.barTwoCss = this.BASE_BAR_CSS;
        this.barThreeCss = this.BASE_BAR_CSS;
        this.barFourCss = `${this.BASE_BAR_CSS} ${this.LAST_BAR_CSS}`;
        break;
    }
  }

  updateBar(): void {
    if (this.isWeak()) {
      this.handleBarsCss(PasswordStrength.WEAK);
    } else if (this.password?.length >= 8 && this.password?.length < 12) {
      this.handleBarsCss(PasswordStrength.FAIR);
      this.text = 'registration.password.fair';
    } else if (this.password.length >= 12 && this.password.length < 17) {
      this.handleBarsCss(PasswordStrength.GOOD);
      this.text = 'registration.password.good';
    } else {
      this.handleBarsCss(PasswordStrength.STRONG);
      this.text = 'registration.password.strong';
    }
  }

  isWeak(): boolean {
    if (!this.password || this.password?.length < 8) {
      this.text = 'registration.error.passwordValidationError.minRequired';
      return true;
    } else if (!/\d/.test(this.password)) {
      this.text = 'registration.error.passwordValidationError.numberRequired';
      return true;
    } else if (!/.*[a-z].*/.test(this.password)) {
      this.text =
        'registration.error.passwordValidationError.lowercaseRequired';
      return true;
    } else if (!/.*[A-Z].*/.test(this.password)) {
      this.text =
        'registration.error.passwordValidationError.uppercaseRequired';
      return true;
    } else {
      return false;
    }
  }
}
