import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SessionTimerService {
  private readonly SESSION_ALIVE_TIME = 10 * 60;
  private sessionAlive = this.SESSION_ALIVE_TIME;
  private clock: ReturnType<typeof setInterval>;
  private canStart = true;

  start(callBack: () => void): void {
    if (this.canStart) {
      this.canStart = false;
      this.sessionAlive = this.SESSION_ALIVE_TIME;
      this.clock = setInterval(() => {
        --this.sessionAlive;
        if (this.sessionAlive <= 0) {
          this.stop();
          callBack();
        }
      }, 1000);
    }
  }

  stop(): void {
    clearInterval(this.clock);
    this.canStart = true;
  }
}
