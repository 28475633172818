export class UserAuthInfo {

    accessToken?: string

    refreshToken?: string
    constructor(accessToken?: string, refreshToken?: string) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
    }

    static anonymous()  {
        return new UserAuthInfo(undefined, undefined)
    }
    isAuthenticated() {
        return this.accessToken && this.refreshToken
    }
}