import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowNavigationService {
  public reload(): void {
    window.location.reload();
  }

  public replace(redirectTo: string): void {
    window.location.replace(redirectTo);
  }

  public goTo(goToUrl: string): void {
    window.location.href = goToUrl;
  }

  public getLocation(): Location {
    return window.location;
  }
}
