import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { RegasComponentsButtonModule } from '@regas/components';
import { RegasSharedModule } from '@regas/shared';
import { ComponentsModule } from '../components/components.module';
import { PipesModule } from '../pipes/pipes.module';
import { DirectivesModule } from '../directives/directives.module';
import { InputModule } from '../components/inputs/input.module';
import { LayoutsModule } from '../layouts/layouts.module';
import { TwoFactorAuthenticationComponent } from './two-factor-authentication.component';
import { AuthenticationCodeComponent } from './authentcation-code/authentication-code.component';
import { NewSmsCodeComponent } from './header/sms-two-factor-authentication-header/new-sms-code/new-sms-code.component';
import { SmsTwoFactorAuthenticationHeaderComponent } from './header/sms-two-factor-authentication-header/sms-two-factor-authentication-header.component';
import { GoogleTwoFactorAuthenticationHeaderComponent } from './header/google-two-factor-authentication-header/google-two-factor-authentication-header.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RegasSharedModule,
    ComponentsModule,
    PipesModule,
    RegasComponentsButtonModule,
    DirectivesModule,
    InputModule,
    LayoutsModule,
    QRCodeModule,
  ],
  declarations: [
    TwoFactorAuthenticationComponent,
    AuthenticationCodeComponent,
    NewSmsCodeComponent,
    SmsTwoFactorAuthenticationHeaderComponent,
    GoogleTwoFactorAuthenticationHeaderComponent,
  ],
  exports: [TwoFactorAuthenticationComponent],
})
export class TwoFactorAuthenticationModule {}
