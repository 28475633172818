import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { JwtIdentityService } from '@regas/shared';
import { EMPTY } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { translations } from '../translations';
import { SurveyRequestDialogService } from './survey-request-dialog.service';

@Component({
  selector: 'regas-survey-request-dialog',
  templateUrl: './survey-request-dialog.component.html',
  styleUrls: ['./survey-request-dialog.component.scss'],
})
export class SurveyRequestDialogComponent {
  readonly isOpen$ = this.service.dialogOpened$;

  readonly checkboxControl = new FormControl(false);
  readonly translations = translations;

  surveyData$ = this.service.surveyData$;

  popupsBlockedAlertVisible = false;

  constructor(
    private readonly service: SurveyRequestDialogService,
    private readonly jwtService: JwtIdentityService,
  ) {}

  close(): void {
    if (this.checkboxControl.value) {
      this.persistSurveyStatusAndRedirect('SKIPPED');
    } else {
      this.service.redirectFn();
    }
  }

  submitAction(): void {
    const openedSurvey = window.open(
      this.surveyData$.getValue()?.link,
      '_blank',
    );
    if (!openedSurvey) {
      this.popupsBlockedAlertVisible = true;
      return;
    } else {
      this.persistSurveyStatusAndRedirect();
    }
  }

  private persistSurveyStatusAndRedirect(
    status: 'SKIPPED' | 'ACCEPTED' = 'ACCEPTED',
  ): void {
    this.service
      .persistSurveyStatus({
        id: this.surveyData$.getValue()?.survey_id as string,
        user: {
          userId: this.jwtService.getUserId(),
          organisationCode: this.jwtService.getOrganisationCode(),
        },
        status,
      })
      .pipe(
        take(1),
        catchError(() => {
          this.service.redirectFn();
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.service.redirectFn();
      });
  }
}
