<ng-container>
  <regas-layout-header
          imageSrc="assets/images/login/data-protected.svg"
          upperText="{{ (isConfigured() ? 'enterTheAuthenticatorCode' :  'twoFactorAuthenticationQrCode.setupAuthentication') | translate: translations }}"
  ></regas-layout-header>
  <div *ngIf="!isConfigured()" class="authentication-configuration" >
    <qrcode [qrdata]="qrCode" [size]="128" [level]="'M'" [width]="150">
    </qrcode>
    <div class="mb-2">
      <span>{{ configuration.secretKey }}</span>
    </div>
    <div class="text-configuration mb-3">
      <div>
        <span>{{
          'twoFactorAuthenticationQrCode.scanQR' | translate: translations
        }}</span>
      </div>
    </div>
  </div>
</ng-container>
