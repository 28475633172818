<div class="input-wrapper">
  <label *ngIf="label" class="form-label" [for]="name">
    {{ label }} <span class="indicator" *ngIf="requiredIndicator">*</span>
  </label>
  <div
    [class]="
      this.control?.errors && this.control.touched
        ? 'phone-container-error'
        : 'phone-container'
    "
  >
    <div class="selector-wrapper">
      <regas-client-selector
        [options]="languageOptions"
        [disabled]="readOnly"
        (prefixChanged)="onPrefixChange($event)"
      ></regas-client-selector>
    </div>
    <div class="phone-input-wrapper">
      <input
        [autocomplete]="autocomplete ? 'autocomplete' : 'off'"
        type="tel"
        [name]="name"
        [id]="id"
        [class]="getInputCssClass()"
        [regasAutofocusDirective]="autoFocus"
        [disabled]="readOnly"
        maxlength="15"
        (blur)="onBlur($event)"
        (focus)="onFocus()"
        (input)="onChange($event)"
        (keydown)="onKeyDown($event)"
        [placeholder]="placeholder"
      />
    </div>
  </div>

  <div class="error-message">
    <regas-input-error
      *ngIf="this.control?.errors?.required && this.control?.touched"
    >
      {{ requiredLabel }}
    </regas-input-error>
    <regas-input-error
      *ngIf="
        !this.control?.errors?.required && this.control?.errors?.invalidData
      "
    >
      {{ invalidLabel }}
    </regas-input-error>
    <regas-input-error
      *ngIf="
        !this.control?.errors?.required &&
        this.externalError &&
        this.control.parent.pristine
      "
    >
      {{ incorrectLabel }}
    </regas-input-error>
  </div>
</div>
