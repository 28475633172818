<regas-alert
  *ngIf="displayAlert"
  [accent]="displayAlert.type ? displayAlert.type : 'danger'"
  class="mb-3"
>
  <div class="alert-content">
    <div class="error-warning-icon" *ngIf="displayAlert.type !== 'success'">
      <div class="warning-icon-wrapper">
        <regas-input-error></regas-input-error>
      </div>
    </div>
    <div
      [class]="
        displayAlert.type === 'success'
          ? 'content-wrapper-success'
          : 'content-wrapper'
      "
    >
      <div class="content">
        <span [innerHTML]="displayAlert.message | translate: translations | upperFirst"></span>
        <small *ngIf="displayAlert.details" class="mt-3 text-body d-block">
          {{ displayAlert.details }}
        </small>
      </div>
    </div>
  </div>
</regas-alert>
