import { Component, EventEmitter, Output, Renderer2 } from '@angular/core';
import { BaseSelectorComponent } from '../base-selector.component';
import { Option } from '../../../models/Option';

@Component({
  selector: 'regas-client-selector',
  templateUrl: './phone-prefix-selector.component.html',
  styleUrls: ['./phone-prefix-selector.component.scss'],
})
export class PhonePrefixSelectorComponent extends BaseSelectorComponent {
  @Output() prefixChanged = new EventEmitter();

  constructor(renderer: Renderer2) {
    super(renderer);
  }

  setOption(option?: Option): void {
    super.setOption(option);
    this.prefixChanged.emit(this.selectedOption?.name);
  }
}
