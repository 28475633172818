import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegasSharedModule } from '@regas/shared';
import { PhonePrefixSelectorComponent } from './phone-prefix-selector/phone-prefix-selector.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';

@NgModule({
  imports: [CommonModule, RegasSharedModule],
  declarations: [PhonePrefixSelectorComponent, LanguageSelectorComponent],
  exports: [PhonePrefixSelectorComponent, LanguageSelectorComponent],
})
export class SelectorModule {}
