import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { Phone } from '../../models/Phone';

export class PhoneFormatter {
  static formatPhone(
    prefix: string,
    number: string,
    length: number | undefined,
  ): string {
    if (length) {
      return '+' + prefix + ' ' + '*'.repeat(length - 3) + number;
    } else {
      return '+' + prefix + ' ' + number;
    }
  }

  static getFullPhoneNumber(phone: Phone): string {
    return '+' + phone.prefix + phone.number;
  }

  static getE164NumberFormat(phonePrefix: string, phoneNumber: string): string {
    try {
      const phoneNumberUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
      const fullPhoneNumber = phoneNumberUtil.parseAndKeepRawInput(
        PhoneFormatter.getFullPhoneNumber({
          prefix: phonePrefix,
          number: phoneNumber,
        }),
      );
      const formattedNumber = PhoneNumberUtil.getInstance().format(
        fullPhoneNumber,
        PhoneNumberFormat.E164,
      );
      return formattedNumber.slice(
        phonePrefix.length + 1,
        formattedNumber.length,
      );
    } catch {
      return phoneNumber;
    }
  }
}
