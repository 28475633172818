import { logInVerb } from './log-in-verb';
import { password } from './password';
import { passwordRequired } from './passwordRequired';
import { usernameRequired } from './usernameRequired';
import { username } from './username';
import { code } from './code';

export const nl = {
  codeRequired: 'Code invullen verplicht',
  fixedLength: `${code.nl} moet 6 nummers lang zijn`,
  company: 'myneva',
  email: 'E-mailadres',
  emailRequired: 'E-mailadres is verplicht',
  enterTheAuthenticatorCode:
    'Voer de code in verstrekt door de Authenticator-App',
  enterTheSmsCode: 'Voer de code in die is verzonden naar',
  error: {
    attemptsLeft1: 'Nog 1 poging beschikbaar.',
    attemptsLeft2: 'Nog 2 pogingen beschikbaar.',
    login: 'Inloggen is niet mogelijk op dit moment.',
    invalidCredentials: 'E-mailadres en/of wachtwoord ongeldig.',
    invalidPasswordChangeData:
      'Het verzoek om het wachtwoord te wijzigen is verlopen of is ongeldig.',
    invalidVerificationCode: 'Verificatie-code ongeldig.',
    ipRestricted:
      'Vanaf dit IP-adres heeft u geen toegang tot deze organisatie.',
    maximumReached:
      'Maximaal aantal pogingen bereikt. Probeer het over enkele minuten opnieuw.',
    noCookieSet:
      'Cookies zijn uitgeschakeld in je browser. Schakel cookies in in de browserinstellingen om door te gaan.',
    passwordSameAsPrevious:
      'Uw wachtwoord is hetzelfde als uw vorige wachtwoord.',
    sms: {
      exceeded:
        "Het maximale aantal verzonden sms'jes is bereikt. Na 10 minuten kunt u de sms opnieuw verzenden.",
      unexpected: 'Er is een onverwachte fout opgetreden. Probeer het opnieuw.',
    },
    sessionExpired: 'Sitzung abgelaufen. Bitte versuche es erneut.',
    unknownError: 'Er is een onverwachte fout opgetreden. Probeer het opnieuw.',
    sso: {
      loginFailed:
        'Er is een SSO fout opgetreden.<br>Incident id: {{incidentId}}.',
    },
  },
  incorrectCredentials: 'Email en/of wachtwoord is onjuist',
  invalidEmailFormat: 'E-mailadres formaat is incorrect',
  forgotPassword: 'Wachtwoord vergeten?',
  logIn: logInVerb.nl,
  maximumReachedTwoFactor:
    'Maximaal aantal pogingen bereikt. Neem contact op met uw organisatie.',
  missingPhoneInfo: 'Om verder te gaan, hebben wij uw telefoonnummer nodig',
  next: 'VOLGENDE',
  notFoundPage: {
    invitation: {
      header: 'De uitnodiging is verlopen',
      content:
        'Als u het probleem niet kunt vinden, neem dan contact op met uw applicatiebeheerder',
    },
    loginMigration: {
      header: 'De inlogpagina is verhuisd!',
      content:
        'De inlogpagina is nu uniek voor jouw organisatie geworden.<br>Neem voor verdere ondersteuning contact op met uw applicatiebeheerder(s) van uw organisatie.',
    },
    header: 'Dit is niet de juiste URL',
    content:
      'Controleer de URL.<br>Als u het probleem niet kunt vinden, neem dan contact op met uw applicatie beheerder.',
  },
  loadingPage: {
    loading: 'Laden',
  },
  password: password.nl,
  passwordRecovery: {
    forcePasswordChange:
      'Vanuit de organisatie is ingesteld dat periodiek het wachtwoord gewijzigd moet worden. U dient nu het wachtwoord te wijzigen',
    passwordRecoveryDoNotExists: 'Wachtwoord herstel link is niet gevonden.',
    resetYourPassword: 'Stel uw wachtwoord opnieuw in',
    requestPasswordChangeBottomText: 'Voer uw nieuw wachtwoord hieronder in',
    requestPasswordChangeButtonText: 'Wijzig wachtwoord',
    requestWasSentUpperText: 'Het verzoek is verzonden',
    requestWasSentBottomText: 'Volg de instructies in de e-mail',
    resetPasswordRequestBottomText:
      'Een e-mail met instructies wordt verstuurd',
  },
  passwordRequired: passwordRequired.nl,
  policy: 'Privacybeleid',
  registration: {
    button: 'Registreer',
    error: {
      codeExpiredOrNotFound: 'Uitnodiging is verlopen',
      activationCodeExpired: 'Activatiecode is verlopen',
      activationCodeNotFound:
        'Er kon geen activatiecode worden gevonden. Neem contact op met de beheerder.',
      emailNotUnique: 'E-mailadres is in gebruik',
      invalid: {
        code: 'Verificatie-code ongeldig',
      },
      incorrectPhoneNumber: 'Telefoonnummer is onjuist.',
      passwordRequired: 'Wachtwoord is verplicht',
      passwordsDontMatch: 'Wachtwoorden komen niet overeen',
      passwordValidationError: {
        uppercaseRequired: 'Zwak - minimaal één hoofdletter is vereist',
        lowercaseRequired: 'Zwak - minimaal één kleine letter is vereist',
        numberRequired: 'Zwak - er is minimaal één cijfer vereist',
        minRequired: 'Zwak - wachtwoord is te kort',
      },
      phoneNumberInvalidFormat: 'Ongeldig telefoonnummer formaat',
      phoneNumberRequired: 'Telefoonnummer is verplicht',
      confirmPasswordRequired: 'Wachtwoordbevestiging is vereist',
      unexpected: 'Er is een onverwachte fout opgetreden. Probeer het opnieuw',
    },
    info: 'Maak een account aan voor',
    label: {
      phoneNumber: 'Mobiele nummer',
      password: 'Maak wachtwoord aan',
      confirmPassword: 'Bevestig wachtwoord',
    },
    password: {
      strong: 'Sterk',
      good: 'Mooi zo',
      fair: 'Goed genoeg',
    },
    placeholder: {
      phoneNumber: 'Voer uw telefoonnummer in',
      password: 'Voer uw wachtwoord in',
      confirmPassword: 'Voer uw wachtwoord opnieuw in',
    },
  },
  twoFactorAuthenticationQrCode: {
    setupAuthentication:
      'Uw applicatiebeheerder heeft twee-factor authenticatie verplicht gesteld voor inloggen',
    scanQR:
      'Scan de QR-code met de authenticator, of vul bovenstaande code daar handmatig in',
  },
  sendNew: 'VERSTUUR NIEUWE CODE',
  sendNewCodeTimerInfo: 'Verstuur nieuwe code over: ',
  smsAttemptsExceeded:
    'Code niet ontvangen? Neem contact op met uw organisatie',
  sso: {
    or: 'OF',
    placeholder: 'Selecteer SSO optie',
    login: 'Inloggen via SSO',
  },
  success: {
    passwordChange: 'Wachtwoord is succesvol gewijzigd.',
    registrationCompleted: 'Registratie succesvol afgerond.',
  },
  terms: 'Gebruiksvoorwaarden',
  title: logInVerb.nl + ' bij myneva',
  typeYourEmail: 'Voer uw e-mailadres in',
  typeYourPassword: 'Voer uw wachtwoord in',
  username: username.nl,
  usernameRequired: usernameRequired.nl,
  surveyRequestDialog: {
    infoContent:
      'Als u de enquête invult, wordt u doorverwezen naar een nieuwe pagina',
    doNotPresent: 'Herinner me er niet weer aan',
    cancel: 'Niet nu',
    submit: 'Enquête invullen',
    popupsBlockedAlert:
      'Jouw browser blokkeert het openen van een nieuw tabblad. Door {{ url }} te vertrouwen in de instellingen van jouw browser kan de template voortaan wel geopend worden.',
  },
};
