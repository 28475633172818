import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { defaultLanguage, SettingsService } from '@regas/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Component({
  selector: 'regas-login-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  endSubscription: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly settingsService: SettingsService,
    private readonly translateService: TranslateService,
    private readonly meta: Meta,
  ) {
    this.addMetaTag();
  }

  ngOnInit(): void {
    this.translateService.setDefaultLang(defaultLanguage);
    this.settingsService
      .getLanguage()
      .pipe(takeUntil(this.endSubscription))
      .subscribe(language => {
        if (language) {
          this.translateService.use(language);
        }
      });
  }

  ngOnDestroy(): void {
    this.endSubscription.next(true);
    this.endSubscription.unsubscribe();
  }

  private addMetaTag(): void {
    const isProduction =
      environment.production &&
      location.origin.indexOf('staging') < 0 &&
      location.origin.indexOf('stage') < 0;

    if (!isProduction) {
      this.meta.addTag({ name: 'robots', content: 'noindex' });
    }
  }
}
