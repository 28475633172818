import {NgModule} from "@angular/core";
import {SsoErrorPageComponent} from "./sso-error-page/sso-error-page.component";
import {SsoSuccessPageComponent} from "./sso-success-page/sso-success-page.component";
import {HttpClientModule} from "@angular/common/http";
import {LoadingPageModule} from "../../components/pages/loading-page/loading-page.module";

@NgModule({
    declarations: [SsoErrorPageComponent, SsoSuccessPageComponent],
    exports: [SsoErrorPageComponent, SsoSuccessPageComponent],
    imports: [
        HttpClientModule,
        LoadingPageModule
    ],
})
export class SsoModule {
}