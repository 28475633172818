<div *ngIf="(isOpen$ | async) === true">
  <div class="dialog dialog--s">
    <div class="dialog__header">
      <span>{{ (surveyData$ | async)?.subject }}</span>
    </div>
    <div class="dialog__content">
      <p>{{ (surveyData$ | async)?.description }}</p>
      <i> {{ 'surveyRequestDialog.infoContent' | translate: translations }} </i>
    </div>
    <div class="dialog__alert" *ngIf="popupsBlockedAlertVisible">
      <regas-client-alert
        [error]="{
          message:
            'surveyRequestDialog.popupsBlockedAlert'
            | translate: translations:{ url: (surveyData$ | async)?.link }
        }"
      >
      </regas-client-alert>
    </div>
    <div class="dialog__footer">
      <regas-checkbox [formControl]="checkboxControl">
        {{ 'surveyRequestDialog.doNotPresent' | translate: translations }}
      </regas-checkbox>
      <div class="actions">
        <a class="login-link" (click)="close()">
          {{ 'surveyRequestDialog.cancel' | translate: translations }}
        </a>
        <regas-button
          [buttonText]="'surveyRequestDialog.submit' | translate: translations"
          (submitForm)="submitAction()"
        ></regas-button>
      </div>
    </div>
  </div>

  <div class="dialog-backdrop"></div>
</div>
