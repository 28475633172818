import { Pipe, PipeTransform } from '@angular/core';
import {
  SettingsService,
  SupportedLanguage,
  TranslatePipe,
} from '@regas/shared';
import { LocalStorageKeys } from '../utils/storage-manager/local-storage-keys';

@Pipe({
  name: 'translate',
})
export class LoginTranslatePipe
  extends TranslatePipe
  implements PipeTransform
{
  constructor(readonly settingsService: SettingsService) {
    super(settingsService);
    if (
      navigator.cookieEnabled &&
      localStorage.getItem(LocalStorageKeys.SELECTED_LANGUAGE)
    ) {
      this.language =
        SupportedLanguage[
          localStorage.getItem(
            LocalStorageKeys.SELECTED_LANGUAGE,
          ) as keyof typeof SupportedLanguage
        ];
    }
  }
}
