import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegasSharedModule } from '@regas/shared';
import { RegasComponentsButtonModule } from '@regas/components';
import { DirectivesModule } from '../../directives/directives.module';
import { SelectorModule } from '../selectors/selector.module';
import { PipesModule } from '../../pipes/pipes.module';
import { PasswordInputComponent } from './inputs/password-input/password-input.component';
import { ClientPhoneInputComponent } from './inputs/client-phone-input/client-phone-input.component';
import { TextInputComponent } from './inputs/text-input/text-input.component';
import { PasswordStrengthComponent } from './inputs/password-input/password-strength/password-strength.component';
import { InputErrorComponent } from './inputs/text-input/input-error/input-error.component';
import { ButtonComponent } from './buttons/button.component';
import { SpinnerComponent } from './buttons/spinner/spinner.component';

@NgModule({
  imports: [
    DirectivesModule,
    SelectorModule,
    CommonModule,
    RegasSharedModule,
    PipesModule,
    RegasComponentsButtonModule,
  ],
  declarations: [
    PasswordInputComponent,
    ClientPhoneInputComponent,
    TextInputComponent,
    PasswordStrengthComponent,
    InputErrorComponent,
    ButtonComponent,
    SpinnerComponent,
  ],
  exports: [
    PasswordInputComponent,
    ClientPhoneInputComponent,
    TextInputComponent,
    InputErrorComponent,
    ButtonComponent,
    SpinnerComponent,
  ],
})
export class InputModule {}
