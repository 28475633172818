import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RegasSharedModule } from '@regas/shared';
import {
  RegasComponentsAlertModule,
  RegasComponentsButtonModule,
  RegasComponentsCardModule,
  RegasComponentsIconModule,
  RegasComponentsProgressIndicatorModule,
} from '@regas/components';
import { PipesModule } from '../../pipes/pipes.module';
import { ComponentsModule } from '../../components/components.module';
import { DirectivesModule } from '../../directives/directives.module';
import { CredentialsComponent } from './credentials.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RegasSharedModule,
    RegasComponentsProgressIndicatorModule,
    RegasComponentsButtonModule,
    RegasComponentsAlertModule,
    RegasComponentsIconModule,
    RegasComponentsCardModule,
    PipesModule,
    ComponentsModule,
    DirectivesModule,
    RouterModule,
  ],
  declarations: [CredentialsComponent],
  exports: [CredentialsComponent],
  providers: [],
})
export class CredentialsModule {}
