import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegasSharedModule } from '@regas/shared';
import { RegasComponentsAlertModule } from '@regas/components';
import { PipesModule } from '../../pipes/pipes.module';
import { InputModule } from '../inputs/input.module';
import { ClientAlertComponent } from './client-alert.component';

@NgModule({
  imports: [
    CommonModule,
    RegasSharedModule,
    PipesModule,
    InputModule,
    RegasComponentsAlertModule,
  ],
  declarations: [ClientAlertComponent],
  exports: [ClientAlertComponent],
})
export class ClientAlertModule {}
