import {Component} from '@angular/core';
import { translations } from '../../../translations';

@Component({
    selector: 'regas-password-request-sent',
    templateUrl: './password-request-sent.component.html',
    styleUrls: ['./password-request-sent.component.scss'],
})
export class PasswordRequestSentComponent {
    translations = translations
}
