import { LoginStage } from '../../login/LoginStage';
import { LoginState } from '../../models/interface/login-state.interface';

export class LoginStageResolver {
  public static resolve(state: LoginState): LoginStage {
    if (this.isAuthenticate(state)) {
      return LoginStage.AUTHENTICATED;
    }

    if (this.isTwoFactor(state)) {
      return LoginStage.TWO_FACTOR;
    }

    if (this.isTwoFactorConfiguration(state)) {
      return LoginStage.TWO_FACTOR_CONFIGURATION;
    }

    if (this.isTwoFactorAuthenticated(state)) {
      return LoginStage.TWO_FACTOR_AUTHENTICATED;
    }

    return LoginStage.CREDENTIALS;
  }

  private static isAuthenticate(state: LoginState): boolean {
    return (
      !!state.credentials &&
      !!state.token &&
      !!state.refreshToken &&
      !state.twoFactorType
    );
  }

  private static isTwoFactor(state: LoginState): boolean {
    return (
      !!state.credentials &&
      !!state.token &&
      !!state.twoFactorType &&
      !state.refreshToken &&
      !state.twoFactor?.secretKey
    );
  }

  private static isTwoFactorConfiguration(state: LoginState): boolean {
    return (
      !!state.credentials &&
      !!state.token &&
      !!state.twoFactorType &&
      !state.refreshToken &&
      !!state.twoFactor?.secretKey
    );
  }

  private static isTwoFactorAuthenticated(state: LoginState): boolean {
    return (
      !!state.credentials &&
      !!state.token &&
      !!state.twoFactorType &&
      !!state.refreshToken
    );
  }
}
