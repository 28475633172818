import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { translations } from '../translations';
import { RouteFollowerService } from '../services/route-follower/route-follower.service';
import { Organisation } from '../models/interface/organisation.interface';
import { OrganisationNameService } from '../services/organisation/organisation-name.service';

@Component({
  selector: 'regas-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  protected readonly endSubscription$ = new Subject<boolean>();

  @Input() displayLanguageSelector = true;
  @Input() displayOrganisationLogo = true;
  @Input() displayNotFound = false;
  @Input() checkForOrganisationSubstitution = true;
  @Input() customHeader = false;
  @Input() displayReturnUrlButton = false;

  loading = true;
  routeChanged = false;
  organisationName: string;
  translations = translations;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly organisationService: OrganisationNameService,
    private readonly routeFollowerService: RouteFollowerService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.routeChanged = this.routeFollowerService.isRouteChanged();
    this.checkForOrganisationName();
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  private checkForOrganisationName(): void {
    if (this.checkForOrganisationSubstitution) {
      const substitution = this.activatedRoute.snapshot.params['substitution'];
      this.organisationService
        .getOrganisationName(substitution)
        .pipe(takeUntil(this.endSubscription$))
        .subscribe(
          (organisation: Organisation) => {
            this.organisationService.organisationName = organisation.name;
            this.organisationName = organisation.name;
            this.loading = false;
          },
          () => {
            this.loading = false;
            this.displayNotFound = true;
          },
        );
    } else {
      this.loading = false;
    }
  }

  backToPreviousView(): void {
    this.router.navigate([this.routeFollowerService.getPreviousUrl()]);
  }
}
