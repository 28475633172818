import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocalStorageKeys } from '../../utils/storage-manager/local-storage-keys';
import { RedirectionLocation } from '../../models/enum/redirection-location.enum';

@Injectable({ providedIn: 'root' })
export class LoginViewGuard implements CanActivate {
  constructor(private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const substitution = localStorage.getItem(LocalStorageKeys.SUBSTITUTION);
    const nonClientUser = localStorage.getItem(
      LocalStorageKeys.NON_CLIENT_USER,
    );
    const goTo = route.queryParams['goto'];
    const isSubstitutionRouteAvailable =
      !!substitution &&
      (!!document.referrer || !!goTo || this.isSsoRedirection());

    if (nonClientUser && isSubstitutionRouteAvailable) {
      this.router.navigate([`${substitution}`], {
        queryParams: {
          goto: goTo,
        },
      });
      return false;
    } else if (isSubstitutionRouteAvailable) {
      this.router.navigate([`client/${substitution}`], {
        queryParams: {
          goto: goTo,
        },
      });
      return false;
    } else {
      return true;
    }
  }

  private isSsoRedirection(): boolean {
    return (
      document.location.href.includes(`sso/${RedirectionLocation.REPORT}`) ||
      document.location.href.includes(
        `sso/${RedirectionLocation.CUSTOMER_PORTAL}`,
      )
    );
  }
}
