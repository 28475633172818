import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { translations } from '../../translations';
import { RegistrationState } from '../../models/interface/registration-state.interface';
import { Phone } from '../../models/Phone';
import { ClientValidator } from '../../utils/client-validator/client-validator';

@Component({
  selector: 'regas-registration-data',
  templateUrl: './registration-data.component.html',
  styleUrls: ['./registration-data.component.scss'],
})
export class RegistrationDataComponent {
  translations = translations;
  @Output() registration = new EventEmitter();
  @Input() email: string;
  @Input() state: RegistrationState;
  @Input() displayPhoneNumberInput = true;

  formGroup = new FormGroup({
    phoneNumber: new FormControl(''),
    password: new FormControl(''),
    confirmPassword: new FormControl(''),
  });

  isConfirmationPasswordValid = ClientValidator.isConfirmationPasswordValid(
    this.formGroup,
  );
  isPasswordMatching = ClientValidator.notifyConfirmationPassword(
    this.formGroup,
  );

  onSubmit(): void {
    if (this.formGroup.valid) {
      const phone: Phone = this.formGroup.get('phoneNumber')?.value as Phone;
      this.registration.emit({
        phoneNumber: phone.number,
        phonePrefix: phone.prefix,
        password: this.formGroup.get('password')?.value,
      });
    }
  }

  isExpired(): boolean {
    return (
      this.state?.errorMessage?.message ===
        'registration.error.codeExpiredOrNotFound' ||
      this.state?.errorMessage?.message ===
        'registration.error.emailNotUnique' ||
      this.state?.errorMessage?.message ===
        'registration.error.activationCodeNotFound'
    );
  }
}
