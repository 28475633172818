import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CookieService, IdentityEffects, identityReducer, SettingsService, TranslatePipe } from '@regas/shared';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { NotFoundPageModule } from "../components/pages/not-found-page/not-found-page.module";
import { AutologoutComponent } from '../logout/autologout.component';
import { ClientLoginModule } from './client-login/client-login.module';
import { UserLoginModule } from './user-login/user-login.module';
import { ClientLoginComponent } from './client-login/client-login.component';
import { SsoModule } from "./sso/sso.module";
import { UserLoginComponent } from './user-login/user-login.component';
import { SsoIntegrationModule } from "./sso-integration/sso-integration.module";

export const storeModuleForFeature: ModuleWithProviders = StoreModule.forFeature(
  'identity',
  identityReducer,
);
export const effectsModuleForFeature: ModuleWithProviders = EffectsModule.forFeature(
  [IdentityEffects],
);

@NgModule({
  imports: [
    storeModuleForFeature,
    effectsModuleForFeature,
    ClientLoginModule,
    UserLoginModule,
    SsoModule,
    NotFoundPageModule,
    SsoIntegrationModule
  ],
  declarations: [
    AutologoutComponent,
  ],
  exports: [
    ClientLoginComponent,
    UserLoginComponent,
    AutologoutComponent,
  ],
  providers: [
    AuthenticationService,
    CookieService,
    SettingsService,
    TranslatePipe,
  ],
})
export class LoginModule {}
