import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Regex } from '../../components/regex';
import { AlertMessage } from '../../models/AlertMessage';
import { translations } from '../../translations';

@Component({
  selector: 'regas-authentication-code',
  templateUrl: './authentication-code.component.html',
  styleUrls: ['./authentication-code.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: AuthenticationCodeComponent,
    },
  ],
})
export class AuthenticationCodeComponent
  implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  private readonly endSubscription$ = new Subject<boolean>();
  private readonly controlsIds: string[] = [
    'firstNumber',
    'secondNumber',
    'thirdNumber',
    'fourthNumber',
    'fifthNumber',
    'sixthNumber',
  ];

  @ViewChildren('codeNumber') numbers: QueryList<ElementRef>;
  @Input() error: AlertMessage | undefined;

  onTouched: () => void;
  onChange: (value: string) => void;
  translations = translations;
  isCodeValid: boolean;

  readonly formGroup = new FormGroup({
    firstNumber: new FormControl('', {
      validators: [Validators.required, Validators.pattern(Regex.digit)],
      updateOn: 'blur',
    }),
    secondNumber: new FormControl(
      { value: '', disabled: true },
      {
        validators: [Validators.required, Validators.pattern(Regex.digit)],
        updateOn: 'blur',
      },
    ),
    thirdNumber: new FormControl(
      { value: '', disabled: true },
      {
        validators: [Validators.required, Validators.pattern(Regex.digit)],
        updateOn: 'blur',
      },
    ),
    fourthNumber: new FormControl(
      { value: '', disabled: true },
      {
        validators: [Validators.required, Validators.pattern(Regex.digit)],
        updateOn: 'blur',
      },
    ),
    fifthNumber: new FormControl(
      { value: '', disabled: true },
      {
        validators: [Validators.required, Validators.pattern(Regex.digit)],
        updateOn: 'blur',
      },
    ),
    sixthNumber: new FormControl(
      { value: '', disabled: true },
      {
        validators: [Validators.required, Validators.pattern(Regex.digit)],
        updateOn: 'blur',
      },
    ),
  });

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.endSubscription$))
      .subscribe(() => {
        this.onChange(this.getValue());
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.error) {
      this.formGroup.markAsPristine();
    }
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(initValues: string): void {
    if (
      initValues &&
      (initValues.length > 5 || !Regex.digit.test(initValues))
    ) {
      throw Error('Invalid initial value');
    }
  }

  onInput(event: Event): void {
    const inputEvent: InputEvent = event as InputEvent;
    if (inputEvent.inputType !== 'deleteContentBackward' && inputEvent.data) {
      const indexInTheControlsIdsArray = this.controlsIds.indexOf(
        (inputEvent.target as HTMLInputElement)?.id,
      );
      this.setFocusOnInputField(indexInTheControlsIdsArray, true);
    }
  }

  onClick(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.setSelectionRange(1, 1);
  }

  onKeyPressed(event: KeyboardEvent): boolean {
    if (
      !Regex.digit.test(event?.key) &&
      event.key !== 'Backspace' &&
      event.key !== 'Tab'
    ) {
      return false;
    }
    const inputElement: HTMLInputElement = event.target as HTMLInputElement;
    if (event.key === 'Backspace' && !inputElement?.value) {
      const indexInTheControlsIdsArray = this.controlsIds.indexOf(
        (event.target as HTMLInputElement)?.id,
      );
      this.setFocusOnInputField(indexInTheControlsIdsArray, false);
    }
    return true;
  }

  resolveInputId(number: number, container: number): string {
    return this.controlsIds[container === 0 ? container + number : number + 3];
  }

  markAsValid(): void {
    this.formGroup.disable();
    this.isCodeValid = true;
  }

  markAsInvalid(): void {
    this.formGroup.enable();
    this.formGroup.markAllAsTouched();
  }

  isCodeRequired(): boolean {
    let display = false;
    Object.keys(this.formGroup.controls).forEach(key => {
      const ngControl: AbstractControl = this.formGroup.controls[key];
      const errors = ngControl.errors;
      if (
        ngControl.touched &&
        ngControl.invalid &&
        errors &&
        errors['required']
      ) {
        display = true;
        this.error = undefined;
      }
    });
    return display;
  }

  isCodeNotNumber(): boolean {
    let invalid = false;
    Object.keys(this.formGroup.controls).forEach(key => {
      const ngControl: AbstractControl = this.formGroup.controls[key];
      const errors = ngControl.errors;
      if (
        ngControl.touched &&
        ngControl.invalid &&
        errors &&
        errors['pattern']
      ) {
        invalid = true;
        this.error = undefined;
      }
    });
    return invalid;
  }

  isInvalid(controlName: string): boolean {
    const control: AbstractControl = this.formGroup.controls[controlName];
    return control.invalid && control.touched;
  }

  private getValue(): string {
    const value = Object.values(this.formGroup.controls)
      .map(control => control.value)
      .filter(number => Boolean(number))
      .join('')
      .toString();
    return value.length === 6 ? value : '';
  }

  private setFocusOnInputField(
    previousInputFieldIndex: number,
    forward: boolean,
  ): void {
    const index = previousInputFieldIndex + (forward ? 1 : -1);
    if (index !== 6 && index !== -1) {
      const nextNumber = this.numbers.toArray()[index];
      this.formGroup.controls[nextNumber.nativeElement.id].enable();
      nextNumber.nativeElement.focus();
    } else if (index !== -1) {
      this.document.getElementById('regas-login-button')?.focus();
    }
  }

  getInputCssClass(controlId: string): string {
    return (this.error && this.formGroup.pristine) || this.isInvalid(controlId)
      ? 'number-input number-error'
      : this.isCodeValid
      ? 'number-input valid-input'
      : 'number-input';
  }
}
