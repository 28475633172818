import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegasSharedModule, SettingsService } from '@regas/shared';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import {
  RegasComponentsAlertModule,
  RegasComponentsButtonModule,
} from '@regas/components';
import { LayoutsModule } from '../layouts/layouts.module';
import { PipesModule } from '../pipes/pipes.module';
import { ComponentsModule } from '../components/components.module';
import { DirectivesModule } from '../directives/directives.module';
import { InputModule } from '../components/inputs/input.module';
import { PasswordRequestDataComponent } from './password-request/password-request-data/password-request-data.component';
import { PasswordRequestSentComponent } from './password-request/password-request-sent/password-request-sent.component';
import { PasswordRequestComponent } from './password-request/password-request.component';
import { PasswordChangeComponent } from './password-change/password-change.component';

@NgModule({
  imports: [
    CommonModule,
    RegasSharedModule,
    LayoutsModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    RegasComponentsButtonModule,
    RegasComponentsAlertModule,
    PipesModule,
    ComponentsModule,
    InputModule,
    DirectivesModule,
  ],
  providers: [SettingsService],
  declarations: [
    PasswordRequestDataComponent,
    PasswordRequestSentComponent,
    PasswordRequestComponent,
    PasswordChangeComponent,
  ],
  exports: [PasswordRequestComponent, PasswordChangeComponent],
})
export class ClientPasswordRecoveryModule {}
