import { Component, Input } from '@angular/core';
import { translations } from '../../../translations';

@Component({
  selector: 'regas-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss'],
})
export class LoadingPageComponent {
  @Input()
  text = 'loadingPage.loading';
  @Input()
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  translations: any = translations;
}
