import { HttpErrorResponse } from '@angular/common/http';

export class ErrorMessageFormatter {
  private static readonly definedErrors: string[] = [
    'error.sms.exceeded',
    'registration.error.emailNotUnique',
    'registration.error.incorrectPhoneNumber',
  ];

  public static produceInvalidInputMessage(
    response: HttpErrorResponse,
    standardMessage: string,
    defaultMessage: string,
  ): string {
    if (this.isAttemptsLeftError(response)) {
      return this.handleAttemptsLeftError(response, standardMessage);
    } else if (this.definedErrors.includes(response.error?.message)) {
      return response.error.message;
    } else {
      return defaultMessage;
    }
  }

  private static isAttemptsLeftError(response: HttpErrorResponse): boolean {
    return (
      response.status === 401 &&
      (response.error?.attemptsLeft || response.error?.attemptsLeft === 0)
    );
  }

  private static handleAttemptsLeftError(
    response: HttpErrorResponse,
    standardMessage: string,
  ): string {
    switch (response.error.attemptsLeft) {
      case 2:
        return 'error.attemptsLeft2';
      case 1:
        return 'error.attemptsLeft1';
      case 0:
        return 'error.maximumReached';
      default:
        return standardMessage;
    }
  }
}
