import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { OriginService } from '@regas/shared';
import { Subdomain } from '@regas/bruce';
import { catchError, map } from 'rxjs/operators';
import { RegistrationState } from '../../../models/interface/registration-state.interface';
import { RegistrationData } from '../../../models/interface/registration-data.interface';
import { SmsResponseToken } from '../../../models/SmsResponseToken';
import { ErrorMessageFormatter } from '../../../utils/attempts-message-formatter/error-message-formatter';
import { CustomQueryEncoder } from '../../../utils/encoder/CustomQueryEncoder';
import { TwoFactorAuthenticationType } from '../../../two-factor-authentication/TwoFactorAuthenticationType.model';
import { EmailResponse } from '../../../models/EmailResponse';

@Injectable({
  providedIn: 'root',
})
export class ClientRegistrationService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly originService: OriginService,
  ) {}

  private readonly state = new BehaviorSubject<RegistrationState>({
    loading: false,
    isActivationFlow: false,
  });

  preValidate(
    registrationData: RegistrationData,
  ): Observable<SmsResponseToken> {
    this.state.next({
      ...this.state.value,
      loading: true,
    });
    return this.httpClient
      .post<SmsResponseToken>(
        this.originService.getUrl(Subdomain.Api, 'registration/validate').href,
        {
          ...registrationData,
          isNewActivationFlow: this.state.getValue().isActivationFlow,
        },
      )
      .pipe(
        catchError(error => {
          this.handleError(
            ErrorMessageFormatter.produceInvalidInputMessage(
              error,
              'error.invalidVerificationCode',
              'registration.error.unexpected',
            ),
          );
          return throwError(error);
        }),
        map((result: SmsResponseToken) => {
          this.state.next({
            ...this.state.value,
            loading: false,
            data: registrationData,
            token: result.token,
            phone: {
              prefix: registrationData.phonePrefix,
              number: registrationData.phoneNumber,
            },
            twoFactorType: TwoFactorAuthenticationType.SMS_CODE,
          });
          return result;
        }),
      );
  }

  validateInvitation(
    email: string,
    validationCode: string,
    substitution: string,
  ): Observable<object> {
    const params: HttpParams = new HttpParams({
      encoder: new CustomQueryEncoder(),
    })
      .set('email', email)
      .set('validationCode', validationCode)
      .set('substitution', substitution);
    return this.httpClient.get(
      this.originService.getUrl(
        Subdomain.Api,
        'registration/validate-invitation',
      ).href,
      { params },
    );
  }

  registerClient(
    state: RegistrationState,
    verificationCode: string | null,
  ): Observable<object> {
    this.state.next({
      ...state,
      loading: true,
      verificationCode: verificationCode,
    });
    return this.httpClient.post(
      this.originService.getUrl(Subdomain.Api, 'registration/client').href,
      Object.assign(this.state.getValue().data, {
        verificationCode: verificationCode,
        isNewActivationFlow: this.state.getValue().isActivationFlow,
      }),
    );
  }

  registerEmployee(state: RegistrationState): Observable<object> {
    this.state.next({
      ...state,
      loading: true,
      verificationCode: null,
    });
    return this.httpClient.post(
      this.originService.getUrl(Subdomain.Api, 'registration').href,
      {
        validationCode: this.state.getValue().data?.validationCode,
        emailAddress: this.state.getValue().data?.emailAddress,
        password: this.state.getValue().data?.password,
        language: this.state.getValue().data?.language,
        isNewActivationFlow: this.state.getValue().isActivationFlow,
      },
    );
  }

  switchToActivationFlow(): void {
    this.state.next({ ...this.state.value, isActivationFlow: true });
  }

  getEmailForActivationRequest(
    activationCode: string,
  ): Observable<EmailResponse> {
    return this.httpClient.get<EmailResponse>(
      this.originService.getUrl(
        Subdomain.Api,
        `registration/email?validationCode=${activationCode}`,
      ).href,
    );
  }

  public handleError(errorResponse: HttpErrorResponse | string): void {
    this.state.next({
      ...this.state.value,
      loading: false,
      errorMessage: {
        message:
          'string' === typeof errorResponse
            ? errorResponse
            : errorResponse?.error?.message || 'registration.error.unexpected',
      },
    });
  }

  getState(): Observable<RegistrationState> {
    return this.state.asObservable();
  }

  clearState(): void {
    this.state.next({
      loading: false,
      isActivationFlow: false,
    });
  }
}
