import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AlertMessage } from '../../models/AlertMessage';
import { translations } from '../../translations';
import { EventAlertService } from '../../services/event/event-alert.service';

@Component({
  selector: 'regas-client-alert',
  templateUrl: './client-alert.component.html',
  styleUrls: ['./client-alert.component.scss'],
})
export class ClientAlertComponent implements OnInit, OnChanges, OnDestroy {
  private readonly endSubscription$ = new Subject<boolean>();
  translations = translations;

  @Input() error: AlertMessage;
  displayAlert: AlertMessage;

  constructor(private readonly eventAlertService: EventAlertService) {}

  ngOnInit(): void {
    this.eventAlertService
      .getObservable()
      .pipe(takeUntil(this.endSubscription$))
      .subscribe((event: AlertMessage) => {
        this.displayAlert = event?.message ? event : this.error;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.error.currentValue) {
      this.eventAlertService.clearMessage();
      this.displayAlert = this.error;
    }
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }
}
