<regas-layout-header
  [upperBoldStyle]="true"
  imageSrc="assets/images/login/data-sent.svg"
  upperText=" {{
    'passwordRecovery.requestWasSentUpperText'
      | translate: translations
      | upperFirst
  }}"
  bottomText=" {{
    'passwordRecovery.requestWasSentBottomText'
      | translate: translations
      | upperFirst
  }}"
>
</regas-layout-header>
