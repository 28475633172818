<regas-layout-header
  [upperBoldStyle]="true"
  imageSrc="assets/images/login/data-received.svg"
  upperText=" {{
    'passwordRecovery.resetYourPassword' | translate: translations | upperFirst
  }}"
  bottomText=" {{
    'passwordRecovery.resetPasswordRequestBottomText'
      | translate: translations
      | upperFirst
  }}"
>
</regas-layout-header>
<div class="content">
  <form [formGroup]="formGroup">
    <regas-text-input
      formControlName="email"
      id="email"
      name="email"
      [autocomplete]="false"
      [required]="true"
      [validateInput]="isEmailFormatValid"
      label="{{ 'email' | translate: translations | upperFirst }}"
      requiredLabel="{{
        'emailRequired' | translate: translations | upperFirst
      }}"
      incorrectLabel="{{
        'incorrectEmail' | translate: translations | upperFirst
      }}"
      invalidLabel=" {{
        'invalidEmailFormat' | translate: translations | upperFirst
      }}"
      placeholder=" {{ 'typeYourEmail' | translate: translations }}"
    >
    </regas-text-input>
    <regas-button
      [formGroup]="formGroup"
      buttonText="{{ 'next' | translate: translations }}"
      (submitForm)="onSubmit()"
    >
    </regas-button>
  </form>
</div>
