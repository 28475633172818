import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subdomain } from '@regas/bruce';
import { OriginService } from '@regas/shared';
import { HttpFactory } from '../../../utils/http-factory/http-factory';
import { RedirectionUrlResponseDto } from '../../../models/RedirectionUrlResponseDto';

@Injectable({ providedIn: 'root' })
export class SsoRedirectionService {
  private readonly MY_DBR = 'sso/redirection/report';
  private readonly CUSTOMER_PORTAL = 'sso/redirection/customer-portal';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly originService: OriginService,
  ) {}

  public redirectToMyDbr(
    token: string,
    regasToken: string,
  ): Promise<RedirectionUrlResponseDto> {
    return this.httpClient
      .post<RedirectionUrlResponseDto>(
        this.originService.getUrl(Subdomain.Base, this.MY_DBR).href,
        { token: token },
        {
          headers: HttpFactory.getHeaders(
            'Authorization',
            'Bearer ' + regasToken,
          ),
        },
      )
      .toPromise();
  }

  public redirectToCustomerPortal(
    token: string,
    url: string,
    regasToken: string,
  ): Promise<RedirectionUrlResponseDto> {
    return this.httpClient
      .post<RedirectionUrlResponseDto>(
        this.originService.getUrl(Subdomain.Base, this.CUSTOMER_PORTAL).href,
        { token: token, url: url },
        {
          headers: HttpFactory.getHeaders(
            'Authorization',
            'Bearer ' + regasToken,
          ),
        },
      )
      .toPromise();
  }
}
