import { Routes } from '@angular/router';
import { ClientLoginComponent } from './client-login.component';

export const clientLoginRouting: Routes = [
  {
    path: 'client/:substitution',
    component: ClientLoginComponent,
  },
  {
    path: 'client',
    component: ClientLoginComponent,
  },
];
