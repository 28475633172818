<div
  class="new-sms-code-container"
  *ngIf="error?.message !== 'error.maximumReached'"
>
  <ng-container *ngIf="!displaySendNewCode && displayTimer">
    <div class="new-code-timer">
      <span *ngIf="newCodeRequestCount < NEW_CODE_MAXIMUM_REQUEST">
        {{ 'sendNewCodeTimerInfo' | translate: translations }}
      </span>
      <regas-timer [time]="newCodeTimer" (callBack)="onTimerEnds()">
      </regas-timer>
    </div>
  </ng-container>
  <span
    *ngIf="displaySendNewCode"
    class="send-new-code"
    (click)="sendNewCode()"
  >
    {{ 'sendNew' | translate: translations }}
  </span>
</div>
