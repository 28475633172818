import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RegasSharedModule } from '@regas/shared';
import { RegasComponentsProgressIndicatorModule } from '@regas/components';
import { PipesModule } from '../../../pipes/pipes.module';
import { LoadingPageComponent } from './loading-page.component';

@NgModule({
  declarations: [LoadingPageComponent],
  exports: [LoadingPageComponent],
  imports: [
    CommonModule,
    RegasComponentsProgressIndicatorModule,
    RegasSharedModule,
    PipesModule,
  ],
})
export class LoadingPageModule {}
