import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Credentials} from '../../models/interface/credentials.interface';
import {LoginState} from '../../models/interface/login-state.interface';
import {AlertMessage} from '../../models/AlertMessage';
import {ClientValidator} from '../../utils/client-validator/client-validator';
import {translations} from '../../translations';


@Component({
  selector: 'regas-credentials',
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.scss'],
})
export class CredentialsComponent implements OnChanges, OnDestroy {
  private readonly endSubscription$ = new Subject<boolean>();
  private readonly EXCLUDED_EXTERNAL_ERRORS = ['error.sms.exceeded', 'error.ipRestricted']
  @Input() credentials: Credentials;

  @Input() state: LoginState;
  @Input() error: AlertMessage;
  @Input() loading = false;
  @Output() credentialsChange = new EventEmitter<Credentials>();
  @Output() input = new EventEmitter<boolean>();
  substitution: string;

  translations = translations;

  isEmailFormatValid = ClientValidator.isEmailFormatValid();
  form = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(private readonly activatedRoute: ActivatedRoute) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.state?.currentValue.loading) {
      this.form.markAsPristine();
    }
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.credentialsChange.emit({
        ...this.form.value,
        organisationSubstitution: this.activatedRoute.snapshot.params[
          'substitution'
        ],
      });
    }
  }

  displayExternalError(error: AlertMessage) {
    return !!error && !this.EXCLUDED_EXTERNAL_ERRORS.includes(error.message || '');
  }
}