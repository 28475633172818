<div class="not-found-container">
  <div class="header-container">
    <div class="header-image-container">
      <img
              src="assets/logo.svg"
              alt="myneva logo"
      />
    </div>
  </div>
  <div class="content-container">
    <div class="image-container">
      <img
        [src]="imageUrl"
        alt="not-found-image"
      />
    </div>
    <div class="header">
      {{ header | translate: translations }}
    </div>
    <div class="content">
      <span [innerHTML]="content | translate: translations"></span>
    </div>
  </div>
  <div class="company">
    <span class="powered-by"> Powered by</span>
    <img
      class="img"
      src="assets/icons/client-user/small-icon/wordmark.svg"
      alt="wordmark"
    />
  </div>
</div>
