<div class="header-text-container" *ngIf="email">
  <span class="info">
    {{ 'registration.info' | translate: translations | upperFirst }}
  </span>
  <div class="email">{{ email }}</div>
</div>
<regas-client-alert [error]="state?.errorMessage"></regas-client-alert>
<form [formGroup]="formGroup">
  <regas-client-phone-input
    *ngIf="displayPhoneNumberInput"
    formControlName="phoneNumber"
    id="phoneNumber"
    name="phoneNumber"
    [autocomplete]="false"
    [requiredIndicator]="true"
    [readOnly]="isExpired()"
    (keydown.enter)="onSubmit()"
    [required]="true"
    label="{{
      'registration.label.phoneNumber' | translate: translations | upperFirst
    }}"
    placeholder="{{
      'registration.placeholder.phoneNumber'
        | translate: translations
        | upperFirst
    }}"
    requiredLabel="{{
      'registration.error.phoneNumberRequired'
        | translate: translations
        | upperFirst
    }}"
    invalidLabel="{{
      'registration.error.phoneNumberInvalidFormat'
        | translate: translations
        | upperFirst
    }}"
  >
  </regas-client-phone-input>
  <div style="overflow: hidden; height: 0">
    <input
      id="hidden-field"
      name="hidden-field"
      tabindex="-1"
      autocomplete="hidden-field"
      style="height: 0; color: transparent; border: none"
    />
  </div>
  <regas-password-input
    formControlName="password"
    id="password"
    name="password"
    [requiredIndicator]="true"
    [passwordStrength]="true"
    [autocomplete]="false"
    [autoFocus]="false"
    [maxLength]="48"
    [readOnly]="isExpired()"
    [required]="true"
    [afterValidation]="isPasswordMatching"
    (keydown.enter)="onSubmit()"
    label="{{
      'registration.label.password' | translate: translations | upperFirst
    }}"
    placeholder="{{
      'registration.placeholder.password' | translate: translations | upperFirst
    }}"
    requiredLabel="{{
      'registration.error.passwordRequired'
        | translate: translations
        | upperFirst
    }}"
  >
  </regas-password-input>
  <regas-password-input
    formControlName="confirmPassword"
    id="confirmPassword"
    name="confirmPassword"
    [requiredIndicator]="true"
    [autocomplete]="false"
    [autoFocus]="false"
    [maxLength]="48"
    [required]="true"
    [readOnly]="isExpired()"
    [validateInput]="isConfirmationPasswordValid"
    (keydown.enter)="onSubmit()"
    label="{{
      'registration.label.confirmPassword'
        | translate: translations
        | upperFirst
    }}"
    placeholder="{{
      'registration.placeholder.confirmPassword'
        | translate: translations
        | upperFirst
    }}"
    requiredLabel="{{
      'registration.error.confirmPasswordRequired'
        | translate: translations
        | upperFirst
    }}"
    invalidLabel="{{
      'registration.error.passwordsDontMatch'
        | translate: translations
        | upperFirst
    }}"
  >
  </regas-password-input>
  <regas-button
    buttonText="{{ 'registration.button' | translate: translations }}"
    [loading]="state?.loading || false"
    (submitForm)="onSubmit()"
    [formGroup]="formGroup"
    [disabled]="isExpired()"
  >
  </regas-button>
</form>
