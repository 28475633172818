import { Component, OnDestroy } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RecoveryState } from '../../models/enum/password-recovery.enum';
import { PasswordRecoveryService } from '../../services/recovery/password-recovery.service';

export enum State {}

@Component({
  selector: 'regas-password-recovery',
  templateUrl: './password-request.component.html',
})
export class PasswordRequestComponent implements OnDestroy {
  private readonly endSubscription$ = new Subject<boolean>();
  state: RecoveryState;
  recoveryState = RecoveryState;
  substitution: string;

  constructor(
    private readonly passwordRecoveryService: PasswordRecoveryService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.state = RecoveryState.REQUEST;
    this.substitution = this.activatedRoute.snapshot.params['substitution'];
  }

  ngOnDestroy(): void {
    this.endSubscription$.next();
    this.endSubscription$.unsubscribe();
  }

  onEmailProvided(email: string): void {
    this.passwordRecoveryService
      .requestPasswordChange({email: email, substitution: this.substitution})
      .pipe(takeUntil(this.endSubscription$))
      .subscribe();
    this.state = RecoveryState.REQUEST_SENT;
  }
}
