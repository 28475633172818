import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SmsTwoFactorAuthenticationConfiguration } from '../TwoFactorAuthenticationHeaderConfiguration';
import { AlertMessage } from '../../../models/AlertMessage';
import { Phone } from '../../../models/Phone';
import { PhoneFormatter } from '../../../utils/phone-formatter/phone-formatter';
import { translations } from '../../../translations';

@Component({
  selector: 'regas-sms-two-factor-authentication-header',
  templateUrl: './sms-two-factor-authentication-header.component.html',
})
export class SmsTwoFactorAuthenticationHeaderComponent {
  readonly translations = translations;

  @Input() configuration: SmsTwoFactorAuthenticationConfiguration;
  @Output() errorEvent = new EventEmitter<AlertMessage>();

  onError(alertMessage: AlertMessage): void {
    this.errorEvent.emit(alertMessage);
  }

  formatPhoneNumber(phone: Phone): string {
    return PhoneFormatter.formatPhone(
      phone.prefix || '',
      phone.number as string,
      phone.length,
    );
  }
}
