import { Routes } from '@angular/router';
import { PasswordRequestComponent } from './password-request/password-request.component';
import { PasswordChangeComponent } from './password-change/password-change.component';

export const passwordRecoveryRouting: Routes = [
  {
    path: ':substitution/new-password',
    component: PasswordChangeComponent,
  },
  {
    path: 'client/:substitution/new-password',
    component: PasswordChangeComponent,
  },
  {
    path: 'client/:substitution/password-recovery',
    component: PasswordRequestComponent,
  },
  {
    path: ':substitution/password-recovery',
    component: PasswordRequestComponent,
  },
];
