import { Routes } from '@angular/router';
import { ClientRegistrationComponent } from './client-registration/client-registration.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { EmployeeRegistrationComponent } from './employee-registration/employee-registration.component';

export const registrationRouting: Routes = [
  {
    path: 'client/:substitution/registration',
    component: ClientRegistrationComponent,
  },
  {
    path: ':substitution/registration',
    component: EmployeeRegistrationComponent,
  },
  {
    path: 'user/registration',
    component: UserRegistrationComponent,
  },
];
