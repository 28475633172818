
export class UrlResolver {
  private static readonly logoutPath = '/login/logout';

  public static isUrlToLogout(currentOrigin: string, url: URL): boolean {
    return (
      url.origin === currentOrigin &&
      (url.pathname.toUpperCase() === this.logoutPath.toUpperCase() ||
        url.pathname.toUpperCase() ===
          this.logoutPath.concat('/').toUpperCase())
    );
  }

  public static isUrlToOlympus(currentOrigin: string, url: URL): boolean {
    return (
      url.origin === currentOrigin &&
      (url.pathname === '/' || url.pathname === '')
    );
  }

  public static getUrl(encodedGoToUrl: string): URL | undefined {
    try {
      return new URL(decodeURI(encodedGoToUrl));
    } catch {
      return;
    }
  }

  public static truncateLastSegment(urlSegments: String[]): string {
    return urlSegments.slice(0, urlSegments.length - 1)
        .join("/")
  }
}
