import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SmsService } from '../../../../services/sms/sms.service';
import { translations } from '../../../../translations';
import { AlertMessage } from '../../../../models/AlertMessage';
import { Phone } from '../../../../models/Phone';

@Component({
  selector: 'regas-new-sms-code',
  templateUrl: './new-sms-code.component.html',
  styleUrls: ['./new-sms-code.component.scss'],
})
export class NewSmsCodeComponent implements OnInit, OnChanges {
  private readonly TIMER_INITIAL_VALUE = 60;
  private readonly NEW_CODE_MAXIMUM_REQUEST_REACHED_MESSAGE: AlertMessage = {
    message: 'smsAttemptsExceeded',
  };
  readonly NEW_CODE_MAXIMUM_REQUEST = 3;
  readonly translations = translations;

  @Input() phone: Phone;
  @Input() token: string;
  @Input() error: AlertMessage | undefined;
  @Output() errorEvent = new EventEmitter<AlertMessage>();

  newCodeTimer: number;
  newCodeRequestCount = 0;
  displaySendNewCode = false;
  displayTimer = true;

  constructor(private readonly smsService: SmsService) {}

  ngOnInit(): void {
    this.newCodeTimer = this.TIMER_INITIAL_VALUE;
  }

  ngOnChanges(): void {
    this.updateTimerVisibility();
  }

  sendNewCode(): void {
    if (this.newCodeRequestCount < this.NEW_CODE_MAXIMUM_REQUEST) {
      this.newCodeRequestCount++;

      if (this.newCodeRequestCount === this.NEW_CODE_MAXIMUM_REQUEST) {
        this.newCodeTimer = this.TIMER_INITIAL_VALUE * 3;
      } else {
        this.newCodeTimer = this.TIMER_INITIAL_VALUE;
      }

      if (this.token) {
        this.smsService.sendSmsCode(this.token, this.phone).subscribe(
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          () => {},
          (httpErrorResponse: HttpErrorResponse) => {
            this.displayTimer =
              httpErrorResponse?.error?.message !== 'error.sms.exceeded';
            this.errorEvent.emit({
              message:
                httpErrorResponse.error.message || 'error.sms.unexpected',
            });
          },
        );
      }
      this.displaySendNewCode = false;
    }
  }

  onTimerEnds(): void {
    if (this.newCodeRequestCount >= this.NEW_CODE_MAXIMUM_REQUEST) {
      this.errorEvent.emit(this.NEW_CODE_MAXIMUM_REQUEST_REACHED_MESSAGE);
      this.displayTimer = false;
    } else {
      this.displaySendNewCode = true;
    }
  }

  private updateTimerVisibility(): void {
    if (this.error?.message === 'error.maximumReached') {
      this.displayTimer = false;
    }
  }
}
