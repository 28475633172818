import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {WebUtils} from "../../../utils/web/web-utils";
import {SsoSuccessService} from "../../../services/sso/sso-success/sso-success.service";
import {LocalStorageKeys} from "../../../utils/storage-manager/local-storage-keys";
import {SsoSuccessResponse} from "../../../models/SsoSuccessResponse";
import {LoginSuccessHandlerService} from "../../../services/login/success/login-success-handler.service";
import {EventAlertService} from "../../../services/event/event-alert.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
    selector: 'regas-sso-success-page',
    templateUrl: './sso-success-page.component.html'
})
export class SsoSuccessPageComponent implements OnInit, OnDestroy {
    private readonly endSubscription: Subject<boolean> = new Subject<boolean>();

    constructor(
        private readonly activeRoute: ActivatedRoute,
        private readonly ssoSuccessService: SsoSuccessService,
        private readonly loginSuccessHandlerService: LoginSuccessHandlerService,
        private readonly router: Router,
        private readonly eventAlertService: EventAlertService,
    ) {
    }

    ngOnInit(): void {
        const requestId = WebUtils.getQueryParam(this.activeRoute, 'requestId');
        const gotoUrl = WebUtils.getQueryParam(this.activeRoute, 'goto');
        const substitution = WebUtils.getQueryParam(this.activeRoute, 'substitution');

        if (!requestId || !substitution) {
            this.router.navigate([`404`])
        }

        localStorage.setItem(LocalStorageKeys.SUBSTITUTION, substitution as string);

        this.ssoSuccessService.getAuthenticationTokens({
            requestId: requestId as string
        }).pipe(takeUntil(this.endSubscription))
            .subscribe({
                next: (response: SsoSuccessResponse) =>
                    this.loginSuccessHandlerService.onSuccess(response.accessToken, response.refreshToken, gotoUrl),
                error: (httpErrorResponse: HttpErrorResponse) => this.onError(httpErrorResponse, substitution as string)
            })
    }

    ngOnDestroy(): void {
        this.endSubscription.next(true);
        this.endSubscription.unsubscribe();
    }

    private onError(httpErrorResponse: HttpErrorResponse, substitution: string): void {
        this.eventAlertService.setMessage({message: 'error.sessionExpired'})
        this.router.navigate([substitution]).then(() => {
                throw Error(`Error has occurred when processing the sso success: ${httpErrorResponse?.message}`)
            }
        );
    }
}
