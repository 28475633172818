import { Input, OnInit, Renderer2 } from '@angular/core';
import { Option } from '../../models/Option';

export abstract class BaseSelectorComponent implements OnInit {
  protected constructor(private readonly renderer: Renderer2) {}

  @Input() options: Option[] = [];
  @Input() disabled = false;

  selectedOption: Option;
  displayOptions = false;

  protected outsideClicked(element: HTMLElement): boolean {
    return !element?.classList.contains('selectable');
  }

  ngOnInit(): void {
    this.renderer.listen('window', 'click', (event: Event) => {
      const target: HTMLElement = event.target as HTMLElement;
      if (event.target) {
        if (this.outsideClicked(target)) {
          this.displayOptions = false;
        } else {
          if (!this.disabled) {
            this.displayOptions = !this.displayOptions;
          }
        }
      }
    });
    this.setOption();
  }

  getAvailableOptions = (): Option[] => {
    const options: Option[] = [];
    for (const option of this.options) {
      if (option.abbreviation !== this.selectedOption?.abbreviation) {
        options.push(option);
      }
    }
    return options;
  };

  setOption(option?: Option): void {
    if (option) {
      this.selectedOption = option;
    } else {
      const language = navigator.cookieEnabled
        ? navigator.language.split('-')[0]
        : 'nl';
      const optionFound = this.options.find((lOption) =>
        lOption.abbreviation.includes(language),
      );
      this.selectedOption = optionFound ? optionFound : this.options[0];
    }
  }
}
