<div class="option-selector" *ngIf="selectedOption">
  <div
    class="option selectable"
    [id]="selectedOption.abbreviation + '-' + selectedOption.name"
  >
    <div class="option-content">
      <div class="icon-wrapper">
        <img
          class="flag-icon selectable"
          [src]="selectedOption.path"
          [alt]="selectedOption.path"
        />
      </div>
      <span class="option-name selectable">+{{ selectedOption.name }}</span>
      <div class="angle-wrapper">
        <img
          *ngIf="!displayOptions"
          class="angle selectable"
          src="assets/icons/client-user/small-icon/angle-down.svg"
          alt="angle-down"
        />
        <img
          *ngIf="displayOptions"
          class="angle selectable"
          src="assets/icons/client-user/small-icon/angle-up.svg"
          alt="angle-up"
        />
      </div>
    </div>
  </div>
  <div class="options-wrapper" *ngIf="displayOptions">
    <div class="options">
      <div
        class="option-to-select"
        *ngFor="let availableOption of getAvailableOptions()"
        [id]="availableOption.abbreviation + '-' + availableOption.name"
        (click)="setOption(availableOption)"
      >
        <div class="option-content">
          <div class="icon-wrapper">
            <img
              class="flag-icon"
              [src]="availableOption.path"
              [alt]="availableOption.path"
            />
          </div>
          <div class="option-name">+{{ availableOption.name }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
