import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { registrationRouting } from './registration/registration-routing';
import { loginRouting } from './login/login.routing';
import { passwordRecoveryRouting } from './password-recovery/password-recovery.routing';
import { LoginViewGuard } from './services/guard/default-login-view-guard.service';
import { NoOrganisationSpecifiedComponent } from "./login/user-login/no-organisation-specified/no-organisation-specified.component";

export const globalRouting: Routes = [
  ...passwordRecoveryRouting,
  ...registrationRouting,
  ...loginRouting,
  {
    path: '**',
    component: NoOrganisationSpecifiedComponent,
    canActivate: [LoginViewGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(globalRouting)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
