import { Component, Injector, Input, ViewChild } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { Dictionary } from '../../../dictionary';
import { BaseInputComponent } from '../base-input.component';
import { PasswordStrengthComponent } from './password-strength/password-strength.component';

@Component({
  selector: 'regas-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: PasswordInputComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: PasswordInputComponent,
    },
  ],
})
export class PasswordInputComponent extends BaseInputComponent {
  @Input() passwordStrength = false;
  @Input() afterValidation: () => void;
  @ViewChild(PasswordStrengthComponent)
  passwordStrengthComponent: PasswordStrengthComponent;
  type = Dictionary.hiddenInputType;

  constructor(injector: Injector) {
    super(injector);
  }

  changePasswordVisibility(): void {
    if (!this.readOnly) {
      if (this.type === Dictionary.hiddenInputType) {
        this.type = Dictionary.visibleIputType;
      } else {
        this.type = Dictionary.hiddenInputType;
      }
    }
  }

  validate(ngControl: AbstractControl): ValidationErrors | null {
    let error: ValidationErrors | null = null;
    super.validate(ngControl);
    if (this.control.valid) {
      if (this.passwordStrength && this.passwordStrengthComponent?.isWeak()) {
        error = { hiddenError: true };
        ngControl.setErrors(error);
      } else {
        ngControl.setErrors(null);
      }
    }
    if (this.afterValidation) {
      this.afterValidation();
    }
    return error;
  }
}
