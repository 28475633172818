import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouteFollowerService implements OnDestroy {
  protected readonly endSubscription$ = new Subject();
  private previousUrl: string;
  private currentUrl: string;

  constructor(private readonly router: Router) {
    this.followUrl();
  }

  ngOnDestroy(): void {
    this.endSubscription$.next();
    this.endSubscription$.unsubscribe();
  }

  protected followUrl(): void {
    this.currentUrl = this.router.url;
    this.router.events
      .pipe(takeUntil(this.endSubscription$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;
        }
      });
  }

  public getPreviousUrl(): string {
    return this.previousUrl;
  }

  public isRouteChanged(): boolean {
    return this.currentUrl !== this.previousUrl;
  }
}
