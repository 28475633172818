import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RegasSharedModule, SettingsService } from '@regas/shared';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  RegasComponentsAlertModule,
  RegasComponentsButtonModule,
  RegasComponentsCardModule,
  RegasComponentsIconModule,
  RegasComponentsProgressIndicatorModule,
} from '@regas/components';
import { PipesModule } from '../../pipes/pipes.module';
import { LayoutsModule } from '../../layouts/layouts.module';
import { ComponentsModule } from '../../components/components.module';
import { DirectivesModule } from '../../directives/directives.module';
import { CredentialsModule } from '../credentials/credentials.module';
import { TwoFactorAuthenticationModule } from '../../two-factor-authentication/two-factor-authentication.module';
import {WindowNavigationService} from "../../services/navigation/navigation.service";
import { ClientLoginComponent } from './client-login.component';
import { ClientPhoneMigrationComponent } from './client-phone-migration/client-phone-migration.component';
import { SsoIntegrationModule } from "../sso-integration/sso-integration.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        RegasSharedModule,
        RegasComponentsProgressIndicatorModule,
        RegasComponentsButtonModule,
        RegasComponentsAlertModule,
        RegasComponentsIconModule,
        RegasComponentsCardModule,
        PipesModule,
        LayoutsModule,
        ComponentsModule,
        DirectivesModule,
        TwoFactorAuthenticationModule,
        CredentialsModule,
        SsoIntegrationModule,
    ],
  declarations: [ClientLoginComponent, ClientPhoneMigrationComponent],
  exports: [ClientLoginComponent],
  providers: [SettingsService, WindowNavigationService],
})
export class ClientLoginModule {}
