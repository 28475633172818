import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationCookieName } from '@regas/bruce';
import { AuthenticationService, CookieService } from '@regas/shared';
import { WebUtils } from '../utils/web/web-utils';
import { RedirectionLocation } from '../models/enum/redirection-location.enum';
import {WindowNavigationService} from "../services/navigation/navigation.service";
import { RedirectionUrlResponseDto } from '../models/RedirectionUrlResponseDto';
import { SsoRedirectionService } from '../services/sso/redirection/sso-redirection.service';
import { LocalStorageKeys } from "../utils/storage-manager/local-storage-keys";

@Component({
  selector: 'regas-sso-redirection-view',
  templateUrl: './sso-redirection-view.component.html',
})
export class SsoRedirectionViewComponent implements OnInit {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly cookieService: CookieService,
    private readonly ssoRedirectionService: SsoRedirectionService,
    private readonly authenticationService: AuthenticationService,
    private readonly windowNavigationService: WindowNavigationService,
  ) {}

  async ngOnInit() {
    const location: RedirectionLocation = this.getLocation();
    const token: string | null = WebUtils.getQueryParam(
      this.activatedRoute,
      'token',
    );
    const url: string | null = WebUtils.getQueryParam(
      this.activatedRoute,
      'url',
    );
    if (this.isRedirectionDataValid(location, token, url)) {
      throw new Error(
        `Missing/Invalid query parameters - token: ${token}, url: ${url}. For redirection location: ${location}`,
      );
    } else {
      await this.authenticationService
        .refreshTokens()
        .then(() => this.redirectTo(location, token as string, url as string))
        .then((redirectionUrlDto: RedirectionUrlResponseDto) =>
          this.windowNavigationService.replace(
            redirectionUrlDto.url,
          ),
        )
        .catch(error => {
          // tslint:disable-next-line:no-console
          console.error(error);
          const substitution = localStorage.getItem(LocalStorageKeys.SUBSTITUTION) || ''
          this.router.navigate([substitution], {queryParams: {goto: window.location.href}});
        });
    }
  }

  private isRedirectionDataValid(
    location: RedirectionLocation,
    token: string | null,
    url: string | null,
  ): boolean {
    return (
      token === null ||
      (location === RedirectionLocation.CUSTOMER_PORTAL && url === null)
    );
  }

  private redirectTo(
    location: RedirectionLocation,
    token: string,
    url: string,
  ): Promise<RedirectionUrlResponseDto> {
    const regasToken = this.cookieService.get(
      AuthenticationCookieName.Access,
    ) as string;
    if (location === RedirectionLocation.REPORT) {
      return this.ssoRedirectionService.redirectToMyDbr(token, regasToken);
    } else {
      return this.ssoRedirectionService.redirectToCustomerPortal(
        token,
        url as string,
        regasToken,
      );
    }
  }

  private getLocation(): RedirectionLocation {
    const location: RedirectionLocation = WebUtils.getPathParam(
      this.activatedRoute,
      'location',
    ) as RedirectionLocation;
    if (
      location !== RedirectionLocation.REPORT &&
      location !== RedirectionLocation.CUSTOMER_PORTAL
    ) {
      throw new Error('Unknown redirection location');
    }
    return location;
  }
}
