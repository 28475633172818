import { HttpParameterCodec } from '@angular/common/http';

/*
 * CustomQueryEncoder
 * Fix plus sign (+) not encoding
 * See: https://github.com/angular/angular/issues/11058#issuecomment-247367318
 */
export class CustomQueryEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
