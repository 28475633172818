import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SsoIntegrationService } from '../../services/sso/integration/sso-integration.service';
import { WebUtils } from '../../utils/web/web-utils';
import { SsoIntegration } from '../../models/SsoIntegration';
import { translations } from '../../translations';
import { SsoUserGroup } from "../../models/SsoUserGroup";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: 'regas-sso-integration',
  templateUrl: './sso-integration.component.html',
  styleUrls: ['./sso-integration.component.scss'],
})
export class SsoIntegrationComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly endSubscription = new Subject<boolean>();
  translations = translations;

  @Input() userGroup: SsoUserGroup
  @Output() errorEvent = new EventEmitter<string>();
  @Output() successEvent = new EventEmitter<string>();
  ssoIntegrations: SsoIntegration[] = [];

  selectedSsoUrl: string | undefined

  form = new FormGroup({
    ssoIntegration: new FormControl(null)
  });


  constructor(
    private readonly ssoIntegrationService: SsoIntegrationService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const organisationSubstitution = WebUtils.getPathParam(
      this.activatedRoute,
      'substitution',
    ) as string;
    const gotoUrl = WebUtils.getQueryParam(this.activatedRoute, 'goto');
    this.ssoIntegrationService
      .getSsoIntegrations(organisationSubstitution, this.userGroup, gotoUrl)
      .pipe(takeUntil(this.endSubscription))
      .subscribe({
        next: (ssoIntegrationsResp: SsoIntegration[]) => {
          this.ssoIntegrations = ssoIntegrationsResp;
          if (this.ssoIntegrations.length === 1) {
            this.selectedSsoUrl = this.ssoIntegrations[0].loginUrl as string
          }
        },
        error: () => this.errorEvent.emit('error.unknownError'),
      });

  }

  ngAfterViewInit(): void {
    this.form.valueChanges
        .pipe(takeUntil(this.endSubscription))
        .subscribe(change => {
          if (change.ssoIntegration) {
            this.selectedSsoUrl = this.ssoIntegrations.find((it) => it.loginUrl === change.ssoIntegration)?.loginUrl as string
          }
        })
  }

  ngOnDestroy(): void {
    this.endSubscription.next(true);
    this.endSubscription.unsubscribe();

  }

}
