<ng-container *ngIf="!loading">
  <ng-container *ngIf="!displayNotFound; else notFound">
    <table aria-describedby="layout">
      <tr>
        <th id="table-body">
          <div class="client-login-container">
            <div class="card">
              <div class="top-wrapper">
                <div
                  class="return-url"
                  *ngIf="displayReturnUrlButton && routeChanged"
                  (click)="backToPreviousView()"
                >
                  <img
                    class="return-img"
                    src="assets/icons/client-user/small-icon/angle-up.svg"
                    alt="return-img"
                  />
                </div>
                <regas-language-selector
                  *ngIf="displayLanguageSelector"
                ></regas-language-selector>
              </div>
              <regas-layout-header
                *ngIf="!customHeader"
                [displayLogo]="true"
                [displayTextPlaceholder]="false"
                [organisationName]="organisationName"
              >
              </regas-layout-header>
              <ng-content></ng-content>
            </div>
          </div>
        </th>
      </tr>
      <tr class="footer">
        <td>
          <div class="content">
            <div
              class="policy text-center"
              *ngIf="
                (displayOrganisationLogo && organisationName) ||
                !displayOrganisationLogo
              "
            >
              <div class="part-policy text-right">
                <span>{{ 'policy' | translate: translations }}</span>
              </div>
              <div class="part-policy-dot">
                <span class="dot-separator">•</span>
              </div>
              <div class="part-policy text-left">
                <span>{{ 'terms' | translate: translations }}</span>
              </div>
            </div>
            <div class="company">
              <span class="powered-by"> Powered by</span>
              <img
                class="img"
                src="assets/icons/client-user/small-icon/wordmark.svg"
                alt="wordmark"
              />
            </div>
          </div>
        </td>
      </tr>
    </table>
  </ng-container>
  <ng-template #notFound>
    <regas-not-found-page></regas-not-found-page>
  </ng-template>
</ng-container>
