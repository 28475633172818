<ng-container *ngIf="ssoIntegrations.length">
    <form [formGroup]="form">
        <div class="login-type-separator">
            <span>{{ 'sso.or' | translate: translations }}</span>
        </div>
        <div class="sso-selection-container">

            <regas-select *ngIf="ssoIntegrations.length > 1"
                    id="ssoIntegrationSelect"
                    placeholder="{{ 'sso.placeholder' | translate: translations }}"
                    class="d-inline-block sso-select mt-2"
                    formControlName="ssoIntegration">
                <regas-option *ngFor="let integration of ssoIntegrations" [value]="integration.loginUrl">{{ integration.displayName }}</regas-option>
            </regas-select>

           <regas-button [id]="'ssoLoginButton'"
                         [mode]="selectedSsoUrl ? 'link' : 'button'"
                         [href]="selectedSsoUrl"
                         buttonText="{{ (ssoIntegrations.length > 1) ? ('sso.login' | translate: translations) : (ssoIntegrations[0].displayName) }}"
                         [disabled] = "!selectedSsoUrl">
           </regas-button>
        </div>
    </form>
</ng-container>
