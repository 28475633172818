import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageKeys } from '../../../utils/storage-manager/local-storage-keys';
import { SsoErrorService } from '../../../services/sso/sso-error/sso-error.service';

@Component({
  selector: 'regas-sso-error-page',
  templateUrl: './sso-error-page.component.html',
})
export class SsoErrorPageComponent implements OnInit {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly ssoErrorService: SsoErrorService,
  ) {}

  ngOnInit(): void {
    const substitution = localStorage.getItem(LocalStorageKeys.SUBSTITUTION);
    if (substitution) {
      this.ssoErrorService.save(
        this.activatedRoute.snapshot.queryParams?.errorCode,
        this.activatedRoute.snapshot.queryParams?.incidentId,
      );
      this.router.navigate([`${substitution}`]);
    } else {
      // tslint:disable-next-line
      console.error('Could not find organisation substitution.');
      this.router.navigate([`404`]);
    }
  }
}
