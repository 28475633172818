import { FormGroup } from '@angular/forms';
import { Regex } from '../../components/regex';

export class ClientValidator {
  static isConfirmationPasswordValid(formGroup: FormGroup): () => boolean {
    return (): boolean =>
      formGroup.controls['password'].value ===
      formGroup.controls['confirmPassword'].value;
  }

  static isEmailFormatValid(): (value: string) => boolean {
    return (value: string): boolean => Regex.email.test(value);
  }

  static notifyConfirmationPassword(formGroup: FormGroup): () => void {
    return (): void => {
      const control = formGroup.controls['confirmPassword'];
      if (
        control.touched &&
        !ClientValidator.isConfirmationPasswordValid(formGroup)()
      ) {
        formGroup.controls['confirmPassword'].setErrors({
          invalidData: true,
        });
      } else if (
        control.touched &&
        ClientValidator.isConfirmationPasswordValid(formGroup)()
      ) {
        formGroup.controls['confirmPassword'].setErrors(null);
      }
    };
  }
}
