import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OriginService, Subdomain } from '@regas/shared';
import { Organisation } from '../../models/interface/organisation.interface';
import { REGAS_IAM_ENDPOINT } from '../../models/REGAS_IAM_ENDPOINT';

@Injectable({
  providedIn: 'root',
})
export class OrganisationNameService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly originService: OriginService,
  ) {}

  organisationName: string;

  public getOrganisationName(substitution: string): Observable<Organisation> {
    return this.httpClient.get<Organisation>(
      this.originService.getUrl(
        Subdomain.Api,
        `${REGAS_IAM_ENDPOINT.authentication}/organisation/${substitution}/name`,
      ).href,
    );
  }
}
