import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private language: string;

  setLanguage(language: string): void {
    this.language = language;
  }

  getLanguage(): string {
    return this.language.substr(0, 2);
  }
}
