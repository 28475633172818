import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { PipesModule } from '../pipes/pipes.module';
import { SurveyRequestDialogComponent } from './survey-request-dialog.component';
import {
  RegasComponentsAlertModule,
  RegasComponentsCheckboxModule,
} from '@regas/components';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SurveyRequestDialogComponent],
  exports: [SurveyRequestDialogComponent],
  imports: [
    ComponentsModule,
    PipesModule,
    CommonModule,
    RegasComponentsCheckboxModule,
    ReactiveFormsModule,
    RegasComponentsAlertModule,
  ],
})
export class SurveyRequestDialogModule {}
