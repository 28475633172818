import { Component, OnInit } from '@angular/core';
import {
  AbstractlogoutComponent,
  AuthenticationService as AuthService,
  CookieService,
  LoggingService,
  OrganisationService,
  OriginService,
  WindowRef,
} from '@regas/shared';

@Component({
  template: '',
})
export class AutologoutComponent extends AbstractlogoutComponent
  implements OnInit {
  constructor(
    public windowRef: WindowRef,
    public authenticationService: AuthService,
    public organisationService: OrganisationService,
    public originService: OriginService,
    public cookieService: CookieService,
    public loggingService: LoggingService,
  ) {
    super(
      windowRef,
      authenticationService,
      organisationService,
      originService,
      cookieService,
      loggingService,
    );
  }

  public ngOnInit(): void {
    const organisation = localStorage.getItem('organisationSubstitution') || '';
    localStorage.removeItem('organisationSubstitution');
    this.initCallback(organisation);
  }

  public initCallback(substitution: string): void {
    this.logOut(substitution);
  }
}
