import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { JwtIdentityService, OriginService } from '@regas/shared';
import { Subdomain } from '@regas/bruce';
import { HttpFactory } from '../utils/http-factory/http-factory';
import { SurveyData } from './model/survey-data';
import { SurveyStatus } from './model/survey-status';

@Injectable({ providedIn: 'root' })
export class SurveyRequestDialogService {
  dialogOpened$ = new BehaviorSubject(false);
  surveyData$ = new BehaviorSubject<SurveyData | null>(null);
  accessToken: string;
  redirectFn: () => void;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly originService: OriginService,
    private readonly jwtService: JwtIdentityService,
  ) {}

  showDialog(surveyData: SurveyData, redirectFn: () => void): void {
    this.surveyData$.next(surveyData);
    this.redirectFn = redirectFn;
    this.dialogOpened$.next(true);
  }

  getSurveyData(accessToken: string): Observable<SurveyData> {
    this.accessToken = accessToken;
    const headers = HttpFactory.getHeaders(
      'Authorization',
      'Bearer ' + this.accessToken,
    );
    return this.httpClient.get<SurveyData>(
      this.originService.getUrl(
        Subdomain.Api,
        `feedback/surveys?userId=${this.jwtService.getUserId()}&organisationCode=${this.jwtService.getOrganisationCode()}`,
      ).href,
      { headers },
    );
  }

  persistSurveyStatus(surveyStatus: SurveyStatus): Observable<void> {
    const headers = HttpFactory.getHeaders(
      'Authorization',
      'Bearer ' + this.accessToken,
    );
    return this.httpClient.post<void>(
      this.originService.getUrl(Subdomain.Api, 'feedback/surveys/results').href,
      surveyStatus,
      { headers },
    );
  }
}
