import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  RegasComponentsAlertModule,
  RegasComponentsButtonModule,
  RegasComponentsProgressIndicatorModule,
} from '@regas/components';
import { LoadingPageModule } from '../../components/pages/loading-page/loading-page.module';
import { LayoutsModule } from '../../layouts/layouts.module';
import { ClientAlertModule } from '../../components/client-alert/client-alert.module';
import { PipesModule } from '../../pipes/pipes.module';
import { ClientLoginModule } from '../client-login/client-login.module';
import { CredentialsModule } from '../credentials/credentials.module';
import { NotFoundPageModule } from "../../components/pages/not-found-page/not-found-page.module";
import { TwoFactorAuthenticationModule } from '../../two-factor-authentication/two-factor-authentication.module';

import { UserLoginComponent } from './user-login.component';
import { NoOrganisationSpecifiedComponent } from "./no-organisation-specified/no-organisation-specified.component";
import { SsoIntegrationModule } from "../sso-integration/sso-integration.module";

@NgModule({
  declarations: [
    UserLoginComponent,
    NoOrganisationSpecifiedComponent
  ],
  imports: [
    LoadingPageModule,
    CommonModule,
    RegasComponentsButtonModule,
    RegasComponentsAlertModule,
    PipesModule,
    LayoutsModule,
    ClientAlertModule,
    ClientLoginModule,
    CredentialsModule,
    RegasComponentsProgressIndicatorModule,
    TwoFactorAuthenticationModule,
    NotFoundPageModule,
    SsoIntegrationModule
  ],
  exports: [UserLoginComponent],
})
export class UserLoginModule {}
