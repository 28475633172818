import { Log, LogLevel } from '@regas/shared';

export class LogFactory {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getLoginError(url: URL, error: any): Log {
    return {
      source: {
        project: 'regas-login-ui',
        className: 'LoginService',
        method: 'checkUrlWhiteList',
      },
      message: `Error check url white list. Url: ${url.origin}${url.pathname}${
        url.search ? '?***' : ''
      }, Error: ${JSON.stringify(error)}`,
      level: LogLevel.Error,
    };
  }
}
