<regas-layout
  [displayNotFound]="false"
  [displayOrganisationLogo]="false"
  [checkForOrganisationSubstitution]="false"
  [customHeader]="true"
  [displayReturnUrlButton]="state === recoveryState.REQUEST"
  [displayLanguageSelector]="state === recoveryState.REQUEST"
>
  <div>
    <regas-password-request
      *ngIf="state === recoveryState.REQUEST"
      (emailProvided)="onEmailProvided($event)"
    >
    </regas-password-request>
    <regas-password-request-sent
      *ngIf="state === recoveryState.REQUEST_SENT"
    >
    </regas-password-request-sent>
  </div>
</regas-layout>
