import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subdomain } from '@regas/bruce';
import { OriginService } from '@regas/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserPassValidation } from '../../models/interface/user-pass-validation.interface';
import { Email } from '../../models/Email';
import { REGAS_IAM_ENDPOINT } from '../../models/REGAS_IAM_ENDPOINT';

@Injectable()
export class AuthenticationService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly originService: OriginService,
  ) {}

  recoverPassword(value: Email): Observable<void> {
    return this.httpClient
      .post(
        this.originService.getUrl(
          Subdomain.Api,
          `${REGAS_IAM_ENDPOINT.authentication}/recoverpassword`,
        ).href,
        value,
      )
      .pipe(map(() => undefined));
  }

  changePassword(value: UserPassValidation): Observable<void> {
    return this.httpClient
      .post(
        this.originService.getUrl(
          Subdomain.Api,
          `${REGAS_IAM_ENDPOINT.authentication}/changepassword`,
        ).href,
        value,
      )
      .pipe(map(() => undefined));
  }
}
