import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OriginService } from '@regas/shared';
import { Subdomain } from '@regas/bruce';
import { HttpFactory } from '../../../utils/http-factory/http-factory';
import { InvitationDetailsDto } from '../../../models/registration/InvitationDetailsDto';

@Injectable({
  providedIn: 'root',
})
export class UserRegistrationService {
  private readonly ENDPOINT = 'registration/invitation/details';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly originService: OriginService,
  ) {}

  validateInvitation(
    email: string,
    validationCode: string,
  ): Observable<InvitationDetailsDto> {
    const params: HttpParams = HttpFactory.getParamsWithQueryEncoder(
      'email',
      email,
    ).set('validationCode', validationCode);
    return this.httpClient.get<InvitationDetailsDto>(
      this.originService.getUrl(Subdomain.Api, this.ENDPOINT).href,
      { params },
    );
  }
}
