<div class="container py-5">
  <div class="col-md-5 mx-auto">
    <div class="card">
      <img
        src="assets/logo.svg"
        alt="myneva logo"
        class="card-img-top regas-logo"
      />
      <div class="card-body pt-0">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
