<ng-container *ngIf="state$ | async as state">
  <regas-layout
    [displayNotFound]="!validationCode"
    [displayLanguageSelector]="!state.data"
    [displayOrganisationLogo]="!state.data"
    [customHeader]="!!state.data"
  >
    <regas-registration-data
      *ngIf="!state.data"
      (registration)="onRegistration($event)"
      [email]="email"
      [state]="state!"
    >
    </regas-registration-data>
    <regas-two-factor-authentication
      *ngIf="state.data"
      [state]="state!"
      [error]="state.errorMessage"
      (authenticationCodeProvided)="onTwoFactor(state, $event)"
    >
    </regas-two-factor-authentication>
  </regas-layout>
</ng-container>
