import { RegasSharedModule } from '@regas/shared';
import { NgModule } from '@angular/core';
import { RegasComponentsProgressIndicatorModule } from '@regas/components';
import { LoadingPageModule } from '../components/pages/loading-page/loading-page.module';
import { SsoRedirectionViewComponent } from './sso-redirection-view.component';

@NgModule({
  declarations: [SsoRedirectionViewComponent],
  imports: [
    RegasComponentsProgressIndicatorModule,
    RegasSharedModule,
    LoadingPageModule,
  ],
  exports: [SsoRedirectionViewComponent],
})
export class SsoRedirectionModule {}
