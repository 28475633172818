import { NgModule } from '@angular/core';
import { RegasSharedModule } from '@regas/shared';
import { LoginTranslatePipe } from './login-translate.pipe';

@NgModule({
  imports: [RegasSharedModule],
  declarations: [LoginTranslatePipe],
  exports: [LoginTranslatePipe],
})
export class PipesModule {}
