import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OriginService, Subdomain } from '@regas/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {WindowNavigationService} from "../../services/navigation/navigation.service";
import { InvitationDetailsDto } from '../../models/registration/InvitationDetailsDto';
import { UserRegistrationService } from '../../services/registration/user/user-registration.service';

@Component({
  selector: 'regas-user-registration',
  templateUrl: './user-registration.component.html',
})
export class UserRegistrationComponent implements OnInit, OnDestroy {
  private readonly REDIRECT_ROOT_URL = 'regas/account';
  private readonly ATTACH_URL = `${this.REDIRECT_ROOT_URL}/accountattach.jsf`;
  private readonly CREATE_URL = `${this.REDIRECT_ROOT_URL}/create.jsf`;
  private readonly endSubscription = new Subject<boolean>();
  isValid = true;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly windowNavigationService: WindowNavigationService,
    private readonly registrationService: UserRegistrationService,
    private readonly originService: OriginService,
  ) {}

  ngOnInit(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const email = queryParams['email'];
    const validationCode = queryParams['validationcode'];

    if (!email || !validationCode) {
      throw Error(
        `Required query params not present. Validation code: ${validationCode}, email: ${email}`,
      );
    }

    this.registrationService
      .validateInvitation(email, validationCode)
      .pipe(takeUntil(this.endSubscription))
      .subscribe({
        next: (dto: InvitationDetailsDto) => {
          this.isValid = dto.isValid;
          if (this.isValid) {
            let url: string;
            if (dto.canBeLinked) {
              url = this.originService.getUrl(
                Subdomain.Base,
                `${
                  this.ATTACH_URL
                }?validationcode=${validationCode}&email=${encodeURIComponent(
                  email,
                )}`,
              ).href;
            } else {
              url = this.originService.getUrl(
                Subdomain.Base,
                `${
                  this.CREATE_URL
                }?validationcode=${validationCode}&email=${encodeURIComponent(
                  email,
                )}`,
              ).href;
            }
            this.windowNavigationService.replace(url);
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.endSubscription.next(true);
    this.endSubscription.unsubscribe();
  }
}
