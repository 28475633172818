import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { translations } from '../../../translations';
import { Phone } from '../../../models/Phone';

@Component({
  selector: 'regas-client-phone-migration',
  templateUrl: './client-phone-migration.component.html',
  styleUrls: ['./client-phone-migration.component.scss'],
})
export class ClientPhoneMigrationComponent {
  translations = translations;

  @Output() phoneNumberProvided = new EventEmitter();

  formGroup = new FormGroup({
    phoneNumber: new FormControl('', Validators.required),
  });

  onSubmit(): void {
    if (this.formGroup.valid) {
      const phone: Phone = {
        prefix: this.formGroup.value.phoneNumber.prefix,
        number: this.formGroup.value.phoneNumber.number,
      };
      this.phoneNumberProvided.emit(phone);
    }
  }
}
