import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { translations } from '../../../translations';
import { ClientValidator } from '../../../utils/client-validator/client-validator';

@Component({
  selector: 'regas-password-request',
  templateUrl: './password-request-data.component.html',
  styleUrls: ['./password-request-data.component.scss'],
})
export class PasswordRequestDataComponent {
  translations = translations;

  @Output() emailProvided = new EventEmitter();

  isEmailFormatValid = ClientValidator.isEmailFormatValid();

  formGroup = new FormGroup({
    email: new FormControl('', Validators.required),
  });

  onSubmit(): void {
    this.emailProvided.emit(this.formGroup.value.email);
  }
}
