<ng-container *ngIf="state$ | async as state">
  <regas-layout
    [displayLanguageSelector]="displayLanguageSelector()"
    [customHeader]="displayCustomHeader()"
  >
    <ng-container *ngIf="displayCredentials()">
      <regas-credentials
        [credentials]="state.credentials"
        [state]="state"
        [error]="error"
        [loading]="currentLoginStage === 'AUTHENTICATED'"
        (credentialsChange)="onCredentials(state, $event)"
      >
      </regas-credentials>
      <regas-sso-integration [userGroup]="'EMPLOYEES'" (errorEvent)="onError($event)">
      </regas-sso-integration>
    </ng-container>
    <regas-two-factor-authentication
      *ngIf="displayTwoFactor()"
      [state]="state"
      [error]="error"
      [loading]="state?.loading || (!!state.token && !!state.refreshToken)"
      (authenticationCodeProvided)="onTwoFactor(state, $event)"
    >
    </regas-two-factor-authentication>
  </regas-layout>
</ng-container>
