import { Component, Input, OnInit } from '@angular/core';
import { translations } from '../../../translations';
import { GoogleAuthenticationTwoFactorConfiguration } from '../TwoFactorAuthenticationHeaderConfiguration';

@Component({
  selector: 'regas-google-two-factor-authentication-header',
  templateUrl: './google-two-factor-authentication-header.component.html',
  styleUrls: ['./google-two-factor-authentication-header.component.scss'],
})
export class GoogleTwoFactorAuthenticationHeaderComponent implements OnInit {
  readonly translations = translations;
  @Input() configuration: GoogleAuthenticationTwoFactorConfiguration;
  qrCode: string;

  ngOnInit(): void {
    if (this.configuration.secretKey) {
      this.qrCode = `otpauth://totp/REGAS:${
        this.configuration.email
      }@${this.replaceForQrCode(
        this.configuration.organisationName as string,
      )}.com?secret=${
        this.configuration.secretKey
      }&issuer=${this.replaceForQrCode(
        this.configuration.organisationName as string,
      )}`;
    }
  }

  isConfigured(): boolean {
    return !this.configuration.secretKey;
  }

  private replaceForQrCode(value: string): string {
    return value.replace(/\s+/g, '');
  }
}
