import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { translations } from '../../../translations';

export type RegasButtonMode = 'button' | 'link';
@Component({
  selector: 'regas-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() mode: RegasButtonMode = 'button';
  @Input() href: string;
  @Input() loading: boolean;
  @Input() buttonText: string;
  @Input() disabled = false;
  @Input() formGroup: FormGroup;
  @Input() smallText = false;
  @Input() id: string;
  @Output() submitForm = new EventEmitter();
  @Output() errorEvent = new EventEmitter();

  protected readonly translations = translations;

  onClick(): void {
    if (!this.formGroup || this.formGroup.valid) {
      this.submitForm.emit();
    } else {
      Object.keys(this.formGroup.controls).forEach(key => {
        this.formGroup.controls[key].markAsTouched();
      });
      this.errorEvent.emit();
    }
  }
}
