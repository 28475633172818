import { Component, Input } from '@angular/core';
import { translations } from '../../translations';

@Component({
  selector: 'regas-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss'],
})
export class LayoutHeaderComponent {
  @Input() imageSrc: string;
  @Input() upperText: string;
  @Input() bottomText: string;
  @Input() upperBoldStyle = false;
  @Input() displayLogo = false;
  @Input() organisationName: string;
  @Input() displayTextPlaceholder = true;

  translations = translations;
}
