<div [class]="value ? 'input-wrapper-cropped' : 'input-wrapper'">
  <label *ngIf="label" class="form-label" [for]="name">
    {{ label }} <span class="indicator" *ngIf="requiredIndicator">*</span>
  </label>
  <input
    [autocomplete]="autocomplete ? 'autocomplete' : 'new-password'"
    [type]="type"
    [name]="name"
    [id]="id"
    [class]="getInputCssClass()"
    [regasAutofocusDirective]="autoFocus"
    [maxLength]="maxLength"
    [disabled]="readOnly"
    (focus)="onFocus()"
    (blur)="onBlur($event)"
    (input)="onChange($event)"
    [placeholder]="placeholder"
  />
  <img
    *ngIf="type === 'password'"
    src="assets/icons/client-user/small-icon/eye.svg"
    alt="eye-icon"
    id="biEye"
    class="bi bi-eye password-eye"
    (click)="changePasswordVisibility()"
  />
  <img
    *ngIf="type === 'text'"
    src="assets/icons/client-user/small-icon/eye-slash.svg"
    alt="eye-slash-icon"
    id="biSlashEye"
    class="bi bi-eye-slash password-eye"
    (click)="changePasswordVisibility()"
  />
  <div class="error-message">
    <regas-input-error
      *ngIf="this.control?.errors?.required && this.control?.touched"
    >
      {{ requiredLabel }}
    </regas-input-error>
    <regas-input-error
      *ngIf="
        !this.control?.errors?.required && this.control?.errors?.invalidData
      "
    >
      {{ invalidLabel }}
    </regas-input-error>
    <regas-input-error
      *ngIf="
        !this.control?.errors?.required &&
        this.externalError &&
        this.control.parent.pristine
      "
    >
      {{ incorrectLabel }}
    </regas-input-error>
  </div>
  <div *ngIf="passwordStrength && !this.control?.errors?.required">
    <regas-password-strength [password]="value"></regas-password-strength>
  </div>
</div>
