import {Component, Injector} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseInputComponent} from '../base-input.component';

@Component({
  selector: 'regas-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['../input-base.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: TextInputComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: TextInputComponent,
    },
  ],
})
export class TextInputComponent extends BaseInputComponent {
  constructor(injector: Injector) {
    super(injector);
  }
}
