import {SsoErrorPageComponent} from "./sso-error-page/sso-error-page.component";
import {Routes} from "@angular/router";
import {SsoSuccessPageComponent} from "./sso-success-page/sso-success-page.component";
import {NotFoundPageComponent} from "../../components/pages/not-found-page/not-found-page.component";

export const ssoRouting: Routes = [
    {
        path: '404',
        component: NotFoundPageComponent,
    },
    {
        path: 'sso-error',
        component: SsoErrorPageComponent,
    },
    {
        path: 'sso-success',
        component: SsoSuccessPageComponent,
    },
]
