import { HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomQueryEncoder } from '../encoder/CustomQueryEncoder';

export class HttpFactory {
  public static getParams(key: string, value: string): HttpParams {
    return new HttpParams().set(key, value);
  }

  public static getParamsWithQueryEncoder(
    key: string,
    value: string,
  ): HttpParams {
    return new HttpParams({
      encoder: new CustomQueryEncoder(),
    }).set(key, value);
  }

  public static getHeaders(key: string, value: string): HttpHeaders {
    return new HttpHeaders().set(key, value);
  }
}
