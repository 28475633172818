import { ActivatedRoute } from '@angular/router';

export class WebUtils {
  public static getQueryParam(
    activatedRoute: ActivatedRoute,
    param: string,
  ): string | null {
    return activatedRoute.snapshot.queryParams[param];
  }
  public static getPathParam(
    activatedRoute: ActivatedRoute,
    param: string,
  ): string | null {
    return activatedRoute.snapshot.params[param];
  }
}
