import { LoginState } from '../../models/interface/login-state.interface';
import { LoginStateStatus } from '../../models/LoginStateStatus';

export class LoginStateResolver {
  private static isTwoFactor(state: LoginState): boolean {
    return !!(
      (state.organisation || state.credentials?.organisationSubstitution) &&
      state.twoFactor &&
      state.twoFactor.verificationCode
    );
  }

  private static isOrganisation(state: LoginState): boolean {
    return !!(
      state.organisation &&
      state.token &&
      !state.credentials?.organisationSubstitution
    );
  }

  private static isCredentials(state: LoginState): boolean {
    return !!state.credentials;
  }

  public static resolve(state: LoginState): LoginStateStatus {
    if (LoginStateResolver.isTwoFactor(state)) {
      return LoginStateStatus.TWO_FACTOR;
    } else if (LoginStateResolver.isOrganisation(state)) {
      return LoginStateStatus.ORGANISATION;
    } else if (LoginStateResolver.isCredentials(state)) {
      return LoginStateStatus.CREDENTIALS;
    } else {
      return LoginStateStatus.ERROR;
    }
  }
}
