import { Injectable } from '@angular/core';
import { Subdomain } from '@regas/bruce';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OriginService } from '@regas/shared';
import { RecoveryPasswordChangeError } from '../../models/RecoveryPasswordChangeError';
import { REGAS_IAM_ENDPOINT } from '../../models/REGAS_IAM_ENDPOINT';
import { PasswordRecoveryRequest } from '../../models/PasswordRecoveryRequest';
import { PasswordChange } from './PasswordChange';
import { CustomQueryEncoder } from '../../utils/encoder/CustomQueryEncoder';

@Injectable({
  providedIn: 'root',
})
export class PasswordRecoveryService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly originService: OriginService,
  ) {}

  public requestPasswordChange(
    passwordRecoveryRequest: PasswordRecoveryRequest,
  ): Observable<void> {
    return this.httpClient.post<void>(
      this.originService.getUrl(
        Subdomain.Api,
        `${REGAS_IAM_ENDPOINT.authentication}/recoverpassword`,
      ).href,
      passwordRecoveryRequest,
    );
  }

  public changePassword(
    passwordChange: PasswordChange,
  ): Observable<RecoveryPasswordChangeError> {
    return this.httpClient.post<RecoveryPasswordChangeError>(
      this.originService.getUrl(
        Subdomain.Api,
        `${REGAS_IAM_ENDPOINT.authentication}/changepassword`,
      ).href,
      passwordChange,
    );
  }

  public exists(
    email: string,
    validationCode: string,
    substitution: string,
  ): Observable<void> {
    const httpParams: HttpParams = new HttpParams({
      encoder: new CustomQueryEncoder(),
    })
      .set('email', email)
      .set('substitution', substitution)
      .set('validationCode', validationCode);
    return this.httpClient.get<void>(
      this.originService.getUrl(
        Subdomain.Api,
        `${REGAS_IAM_ENDPOINT.authentication}/recoverpassword`,
      ).href,
      { params: httpParams },
    );
  }
}
