<regas-layout-header
  imageSrc="assets/images/login/data-protected.svg"
  upperText="{{ 'enterTheSmsCode' | translate: translations }}"
  [bottomText]="formatPhoneNumber(configuration.phone)"
></regas-layout-header>
<regas-new-sms-code
  [phone]="configuration.phone"
  [token]="configuration.token"
  [error]="configuration.error"
  (errorEvent)="onError($event)"
>
</regas-new-sms-code>
