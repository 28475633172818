import { NgModule } from '@angular/core';
import { RegasSharedModule } from '@regas/shared';
import { CommonModule } from '@angular/common';
import { RegasComponentsButtonModule } from '@regas/components';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '../components/components.module';
import { PipesModule } from '../pipes/pipes.module';
import { LayoutComponent } from './layout.component';
import { DefaultLayoutComponent } from './default/default-layout.component';
import { LayoutHeaderComponent } from './layout-header/layout-header.component';

@NgModule({
  imports: [
    RegasSharedModule,
    ComponentsModule,
    CommonModule,
    RegasComponentsButtonModule,
    RouterModule,
    PipesModule,
  ],
  declarations: [
    LayoutComponent,
    DefaultLayoutComponent,
    LayoutHeaderComponent,
  ],
  exports: [LayoutComponent, DefaultLayoutComponent, LayoutHeaderComponent],
})
export class LayoutsModule {}
