<div class="form-input-wrapper button-placeholder">
    <ng-container *ngIf="mode == 'button'; else linkMode">
        <button type="button"
                (click)="onClick()"
                [disabled]="disabled"
                [class]="{ 'small-text': smallText }"
                [id]="id || 'regas-login-button'"
                class="mb-3 login-submit new-input-styling"
                regasPrimaryButton>
            <ng-container *ngIf="!loading; else loadingSpinner">
                {{ buttonText }}
            </ng-container>
            <ng-template #loadingSpinner>
                <regas-spinner></regas-spinner>
            </ng-template>
        </button>
    </ng-container>
    <ng-template #linkMode>
        <a regasPrimaryButton [id]="id || 'regas-login-button'" class="mb-3 new-input-styling" [href]="href">
            {{ buttonText }}
        </a>
    </ng-template>
</div>
