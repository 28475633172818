import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OriginService, Subdomain } from '@regas/shared';
import { Observable } from 'rxjs';
import { REGAS_IAM_ENDPOINT } from '../../models/REGAS_IAM_ENDPOINT';
import { Phone } from '../../models/Phone';

@Injectable({
  providedIn: 'root',
})
export class SmsService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly originService: OriginService,
  ) {}

  public sendSmsCode(temporaryToken: string, phone?: Phone): Observable<void> {
    return this.httpClient.post<void>(
      this.originService.getUrl(
        Subdomain.Api,
        `${REGAS_IAM_ENDPOINT.authentication}/send/sms`,
      ).href,
      {
        temporaryToken: temporaryToken,
        phonePrefix: phone?.length ? undefined : phone?.prefix,
        phoneNumber: phone?.length ? undefined : phone?.number,
      },
    );
  }
}
