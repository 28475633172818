<div class="client-layout-header">
  <div class="content-wrapper">
    <div
      [class]="
        displayTextPlaceholder ? 'logo-placeholder' : 'logo-placeholder-no-text'
      "
      *ngIf="displayLogo"
    >
      <div class="logo-wrapper">
        <img src="assets/favicon/favicon.svg" alt="header-image" />
      </div>
      <div class="organisation-name">
        {{ organisationName }}
      </div>
    </div>
    <div class="image-placeholder" *ngIf="!displayLogo">
      <img [src]="imageSrc" alt="header-image" />
    </div>
    <div class="text-placeholder" *ngIf="displayTextPlaceholder">
      <span [class]="upperBoldStyle ? 'bold-text' : 'normal-text'">
        {{ upperText }}
      </span>
      <br />
      <span [class]="upperBoldStyle ? 'normal-text' : 'bold-text'">
        {{ bottomText }}
      </span>
    </div>
  </div>
</div>
