import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[regasCodeNumber]',
})
export class CodeFocusDirective implements AfterViewInit {
  constructor(private readonly el: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.el.nativeElement.id === 'firstNumber') {
      this.el.nativeElement.focus();
    }
  }
}
