<div class="container loading-container">
  <div class="h-100 d-flex align-items-center justify-content-center">
    <div class="text-center">
      <div class="d-flex justify-content-center">
        <regas-progress-indicator
          class="loading-progress-indicator"
        ></regas-progress-indicator>
      </div>
      <div class="text-center">
        <span class="loading-text">
          {{ text | translate: translations | upperFirst }}
        </span>
      </div>
    </div>
  </div>
</div>
