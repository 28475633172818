<regas-layout [customHeader]="true" [displayReturnUrlButton]="false">
  <ng-container *ngIf="!token; else forcePasswordChange">
    <regas-layout-header
            [upperBoldStyle]="true"
            imageSrc="assets/favicon/favicon.svg"
            [displayLogo]="true"
            [organisationName]="organisationName"
            upperText=" {{
      'passwordRecovery.resetYourPassword'
        | translate: translations
        | upperFirst
    }}"
            bottomText=" {{
      'passwordRecovery.requestPasswordChangeBottomText'
        | translate: translations
        | upperFirst
    }}"
    >
    </regas-layout-header>
  </ng-container>
  <regas-client-alert [error]="errorMessage"> </regas-client-alert>
  <form [formGroup]="formGroup">
    <regas-password-input
      formControlName="password"
      id="password"
      name="password"
      [passwordStrength]="true"
      [autocomplete]="false"
      [autoFocus]="false"
      [maxLength]="48"
      [required]="true"
      [readOnly]="!exists"
      [afterValidation]="isPasswordMatching"
      label="{{
        'registration.label.password' | translate: translations | upperFirst
      }}"
      placeholder="{{
        'typeYourPassword' | translate: translations | upperFirst
      }}"
      requiredLabel="{{
        'registration.error.passwordRequired'
          | translate: translations
          | upperFirst
      }}"
    >
    </regas-password-input>
    <regas-password-input
      formControlName="confirmPassword"
      id="confirmPassword"
      name="confirmPassword"
      [autocomplete]="false"
      [autoFocus]="false"
      [maxLength]="48"
      [required]="true"
      [validateInput]="isConfirmationPasswordValid"
      [readOnly]="!exists"
      label="{{
        'registration.label.confirmPassword'
          | translate: translations
          | upperFirst
      }}"
      placeholder="{{
        'registration.placeholder.confirmPassword'
          | translate: translations
          | upperFirst
      }}"
      requiredLabel="{{
        'registration.error.confirmPasswordRequired'
          | translate: translations
          | upperFirst
      }}"
      invalidLabel="{{
        'registration.error.passwordsDontMatch'
          | translate: translations
          | upperFirst
      }}"
    >
    </regas-password-input>
    <regas-button
      buttonText="{{
        'passwordRecovery.requestPasswordChangeButtonText'
          | translate: translations
      }}"
      [smallText]="true"
      [formGroup]="formGroup"
      (submitForm)="onSubmit()"
      [loading]="loading"
      [disabled]="!exists"
    >
    </regas-button>
  </form>
</regas-layout>


<ng-template #forcePasswordChange>
  <regas-layout-header
          imageSrc="assets/favicon/favicon.svg"
          [displayLogo]="true"
          [organisationName]="organisationName"
          upperText=" {{
      'passwordRecovery.forcePasswordChange'
        | translate: translations
        | upperFirst
    }}"
  >
  </regas-layout-header>
</ng-template>