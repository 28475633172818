import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RegasSharedModule } from '@regas/shared';
import { PipesModule } from '../../../pipes/pipes.module';
import { NotFoundPageComponent } from './not-found-page.component';

@NgModule({
  declarations: [NotFoundPageComponent],
  exports: [NotFoundPageComponent],
  imports: [
    CommonModule,
    RegasSharedModule,
    PipesModule,
    RouterModule,
  ],
})
export class NotFoundPageModule {}
