<div class="password-strength-wrapper">
  <div class="indicators-container">
    <div [class]="barOneCss"></div>
    <div [class]="barTwoCss"></div>
    <div [class]="barThreeCss"></div>
    <div [class]="barFourCss"></div>
  </div>
  <div class="strength-info">
    <span>{{ text | translate: translations }}</span>
  </div>
</div>
