import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertMessage } from '../../models/AlertMessage';

@Injectable({
  providedIn: 'root',
})
export class EventAlertService {
  private readonly eventMessage: BehaviorSubject<
    AlertMessage
  > = new BehaviorSubject<AlertMessage>({});

  public setMessage(message: AlertMessage): void {
    this.eventMessage.next(message);
  }

  public clearMessage(): void {
    this.eventMessage.next({});
  }

  public getObservable(): Observable<AlertMessage> {
    return this.eventMessage.asObservable();
  }
}
