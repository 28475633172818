<div class="input-wrapper">
  <label *ngIf="label" class="form-label" [for]="name">
    {{ label }} <span class="indicator" *ngIf="requiredIndicator">*</span>
  </label>
  <input
    [autocomplete]="autocomplete ? 'autocomplete' : 'off'"
    [type]="type"
    [name]="name"
    [id]="id"
    [class]="getInputCssClass()"
    [regasAutofocusDirective]="autoFocus"
    [maxLength]="maxLength"
    [disabled]="readOnly"
    (focus)="onFocus()"
    (blur)="onBlur($event)"
    (input)="onChange($event)"
    [placeholder]="placeholder"
  />
  <div class="error-message">
    <regas-input-error
      *ngIf="this.control?.errors?.required && this.control?.touched"
    >
      {{ requiredLabel }}
    </regas-input-error>
    <regas-input-error
      *ngIf="
        !this.control?.errors?.required && this.control?.errors?.invalidData
      "
    >
      {{ invalidLabel }}
    </regas-input-error>
    <regas-input-error
      *ngIf="
        !this.control?.errors?.required &&
        this.externalError &&
        this.control.parent.pristine
      "
    >
      {{ incorrectLabel }}
    </regas-input-error>
  </div>
</div>
