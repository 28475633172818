import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {OriginService, Subdomain} from "@regas/shared";
import {UserData} from "../../models/interface/userdata.interface";

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {

  constructor(private readonly httpClient: HttpClient, private readonly originService: OriginService) {
  }

  public getPersonData(headers: HttpHeaders): Promise<UserData> {
    return this.httpClient
        .get<UserData>(
            this.originService.getUrl(Subdomain.Api, 'persons/current').href,
            {
              headers,
            },
        )
        .toPromise();
  }

  public getRedirectUrl(
      headers: HttpHeaders,
      userRole: string,
  ): Promise<string> {
    return this.httpClient
        .get(
            this.originService.getUrl(
                Subdomain.Api,
                `users/config/urlforuserrole?role=${userRole}`,
            ).href,
            {
              headers,
              responseType: 'text',
            },
        )
        .toPromise();
  }
}
