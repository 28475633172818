import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { translations } from '../translations';
import { AlertMessage } from '../models/AlertMessage';
import { State } from '../models/interface/state.interface';
import { Regex } from '../components/regex';
import { AuthenticationCodeComponent } from './authentcation-code/authentication-code.component';
import {
  GoogleAuthenticationTwoFactorConfiguration,
  SmsTwoFactorAuthenticationConfiguration,
} from './header/TwoFactorAuthenticationHeaderConfiguration';
import { TwoFactorAuthenticationType } from './TwoFactorAuthenticationType.model';

@Component({
  selector: 'regas-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.scss'],
})
export class TwoFactorAuthenticationComponent implements OnInit, OnChanges {
  private readonly INVALID_CODE: AlertMessage = {
    message: 'error.invalidVerificationCode',
  };
  readonly translations = translations;

  @Output() authenticationCodeProvided: EventEmitter<string> = new EventEmitter<
    string
  >();
  @Input() state: State;
  @Input() error: AlertMessage | undefined;
  @Input() loading: boolean;

  @ViewChild(AuthenticationCodeComponent)
  authenticationCodeComponent: AuthenticationCodeComponent;
  formGroup: FormGroup;
  googleConfiguration: GoogleAuthenticationTwoFactorConfiguration;
  smsConfiguration: SmsTwoFactorAuthenticationConfiguration;

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      authenticationCode: new FormControl('', [
        Validators.required,
        Validators.pattern(Regex.digit),
      ]),
    });
    this.setConfigurationForHeader();
  }

  ngOnChanges(): void {
    this.updateComponentStatus();
  }

  private setConfigurationForHeader(): void {
    if (
      this.state.twoFactorType ===
      TwoFactorAuthenticationType.GOOGLE_AUTHENTICATOR
    ) {
      this.googleConfiguration = {
        secretKey: this.state.twoFactor?.secretKey,
        email: this.state.credentials?.email,
        organisationName: this.state.organisation?.name,
      } as GoogleAuthenticationTwoFactorConfiguration;
    } else {
      this.smsConfiguration = {
        error: this.error,
        phone: this.state.phone,
        token: this.state.token,
      } as SmsTwoFactorAuthenticationConfiguration;
    }
  }

  private updateComponentStatus(): void {
    if (
      !!this.state?.token &&
      !!this.state.refreshToken &&
      !this.state?.error
    ) {
      this.error = undefined;
      this.authenticationCodeComponent.markAsValid();
    }
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      this.authenticationCodeProvided.emit(
        this.formGroup.value.authenticationCode,
      );
    } else {
      this.authenticationCodeComponent.markAsInvalid();
    }
  }

  public getAlertError(): AlertMessage | undefined {
    return this.error?.message !== 'error.invalidVerificationCode'
      ? this.error
      : undefined;
  }

  public getAuthenticationCodeError(): AlertMessage | undefined {
    return this.isVerificationCodeAttemptsError(this.error)
      ? this.INVALID_CODE
      : undefined;
  }

  private isVerificationCodeAttemptsError(
    error: AlertMessage | undefined,
  ): boolean {
    return (
      error?.message === 'error.invalidVerificationCode' ||
      error?.message === 'error.attemptsLeft2' ||
      error?.message === 'error.attemptsLeft1' ||
      error?.message === 'error.maximumReached'
    );
  }

  public readonly onError = (alertMessage: AlertMessage): void => {
    this.error = alertMessage;
  };
}
