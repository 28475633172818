import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {OriginService, Subdomain} from "@regas/shared";
import {Observable} from "rxjs";
import {SsoSuccessRequest} from "../../../models/SsoSuccessRequest";
import {SsoSuccessResponse} from "../../../models/SsoSuccessResponse";

@Injectable({
  providedIn: 'root',
})
export class SsoSuccessService {

  constructor(private readonly httpClient: HttpClient, private readonly originService: OriginService) {
  }

  public getAuthenticationTokens(ssoSuccessRequest: SsoSuccessRequest): Observable<SsoSuccessResponse> {
    return this.httpClient.post<SsoSuccessResponse>(
        this.originService.getUrl(Subdomain.Api, 'sso-requests').href, ssoSuccessRequest);
  }
}
