import { Component, Renderer2 } from '@angular/core';
import { LanguageService } from '../../../services/language/language.service';
import { BaseSelectorComponent } from '../base-selector.component';
import {WindowNavigationService} from "../../../services/navigation/navigation.service";
import { Option } from '../../../models/Option';
import { LocalStorageKeys } from '../../../utils/storage-manager/local-storage-keys';

@Component({
  selector: 'regas-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent extends BaseSelectorComponent {
  private readonly BASE_PATH = 'assets/icons/flags/';
  private readonly defaultOption: Option = {
    path: this.BASE_PATH + 'nl.svg',
    abbreviation: 'nlNl',
    name: 'Nederlands',
  };
  selectedOption: Option;
  options = [
    this.defaultOption,
    {
      path: this.BASE_PATH + 'gb.svg',
      abbreviation: 'enGb',
      name: 'English (UK)',
    },
    {
      path: this.BASE_PATH + 'fr.svg',
      abbreviation: 'fr',
      name: 'Francais',
    },
    {
      path: this.BASE_PATH + 'de.svg',
      abbreviation: 'deDe',
      name: 'Deutsch',
    },
  ];

  constructor(
    renderer: Renderer2,
    private readonly windowNavigationService: WindowNavigationService,
    private readonly languageService: LanguageService,
  ) {
    super(renderer);
    this.setOption();
  }

  protected outsideClicked(element: HTMLElement): boolean {
    return !element?.classList.contains('selectable-lng-box');
  }

  setOption(option?: Option): void {
    const userLanguage = option?.abbreviation
      ? option?.abbreviation
      : navigator.cookieEnabled &&
        localStorage.getItem(LocalStorageKeys.SELECTED_LANGUAGE) !== null
      ? (localStorage.getItem(LocalStorageKeys.SELECTED_LANGUAGE) as string)
      : navigator.language.split('-')[0];
    const language = this.options.find(lOption =>
      lOption.abbreviation.includes(userLanguage),
    );
    this.selectedOption = !language ? this.defaultOption : language;
    this.languageService.setLanguage(this.selectedOption.abbreviation);
    if (option) {
      localStorage.setItem(
        LocalStorageKeys.SELECTED_LANGUAGE,
        this.selectedOption.abbreviation,
      );
      this.windowNavigationService.reload();
    }
  }
}
