<ng-container *ngIf="state$ | async as state">
  <regas-layout
    [displayLanguageSelector]="!state.token"
    [customHeader]="!!state.credentials && !!state.token"
  >
    <regas-credentials
      *ngIf="!state.credentials || !state.token"
      [credentials]="state.credentials"
      [state]="state"
      [error]="error"
      (credentialsChange)="onCredentials(state, $event)"
    ></regas-credentials>
    <regas-sso-integration
      *ngIf="!state.credentials || !state.token"
      [userGroup]="'CLIENT_USERS'"
      (errorEvent)="onError($event)"
    >
    </regas-sso-integration>
    <regas-client-phone-migration
      *ngIf="state.credentials && !state.phone && state.token"
      (phoneNumberProvided)="onPhoneNumberProvided(state, $event)"
    ></regas-client-phone-migration>
    <regas-two-factor-authentication
      *ngIf="state.phone && state.credentials && state.token"
      [state]="state"
      [error]="error"
      [loading]="state?.loading || (!!state.token && !!state.refreshToken)"
      (authenticationCodeProvided)="onTwoFactor(state, $event)"
    >
    </regas-two-factor-authentication>
  </regas-layout>
</ng-container>
