import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { OriginService } from '@regas/shared';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ChangeDetectorRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { combineLatest, Observable, Subject } from 'rxjs';
import { LoginState } from '../models/interface/login-state.interface';
import { LoginStatus } from '../models/enum/error-codes.enum';
import { AlertMessage } from '../models/AlertMessage';
import { Credentials } from '../models/interface/credentials.interface';
import { ErrorMessageFormatter } from '../utils/attempts-message-formatter/error-message-formatter';
import { LocalStorageKeys } from '../utils/storage-manager/local-storage-keys';
import { SessionTimerService } from '../services/session/session-timer.service';
import { EventAlertService } from '../services/event/event-alert.service';
import { LoginService } from '../services/login/login.service';
import { WebUtils } from '../utils/web/web-utils';
import { translations } from '../translations';
import { LoginSuccessHandlerService } from '../services/login/success/login-success-handler.service';
import { UserAuthInfo } from '../services/login/UserAuthInfo';

export abstract class BaseLogin implements OnInit, OnDestroy {
  protected readonly defaultErrorMessage = 'error.login';
  protected readonly endSubscription$ = new Subject<boolean>();
  translations = translations;
  state$: Observable<LoginState>;
  error: AlertMessage | undefined;
  surveysEnabled = true;

  protected constructor(
    protected readonly eventAlertService: EventAlertService,
    protected readonly sessionTimerService: SessionTimerService,
    @Inject(DOCUMENT) protected readonly document: Document,
    protected readonly httpClient: HttpClient,
    protected readonly loginService: LoginService,
    protected readonly originService: OriginService,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly loginSuccessHandlerService: LoginSuccessHandlerService,
  ) {}

  ngOnInit(): void {
    this.doBeforeInit();

    const existingSubstitution = localStorage.getItem(
      LocalStorageKeys.SUBSTITUTION,
    );
    const pathParamSubstitution = WebUtils.getPathParam(
      this.activatedRoute,
      'substitution',
    ) as string;

    if (
      existingSubstitution &&
      existingSubstitution !== pathParamSubstitution
    ) {
      console.warn('Clearing any existing session for another tenant.');
      this.loginService.clearUserAuthInfo();
    }

    localStorage.setItem(LocalStorageKeys.SUBSTITUTION, pathParamSubstitution);

    if (!navigator.cookieEnabled) {
      this.showError('error.noCookieSet');
    }

    this.state$ = combineLatest([
      this.loginService.getState(),
      this.loginService.getUserAuthInfo(),
    ]).pipe(
      tap(([state, userAuthInfo]) => this.handleAttempt(state, userAuthInfo)),
      map(([state, _]) => state),
      tap(() => this.changeDetectorRef.detectChanges()),
    );

    this.doAfterInit();
  }

  protected abstract doBeforeInit(): void;

  protected abstract doAfterInit(): void;

  ngOnDestroy(): void {
    this.sessionTimerService.stop();
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  protected handleAttempt(state: LoginState, userAuthInfo: UserAuthInfo): void {
    if (userAuthInfo.isAuthenticated()) {
      this.loginSuccessHandlerService.onSuccess(
        // tslint:disable-next-line:no-non-null-assertion
        userAuthInfo.accessToken!,
        // tslint:disable-next-line:no-non-null-assertion
        userAuthInfo.refreshToken!,
        WebUtils.getQueryParam(this.activatedRoute, 'goto'),
        this.surveysEnabled,
      );
      return;
    }

    if (state.token && state.credentials) {
      this.sessionTimerService.start(this.clearSession);
    }

    if (state.loading) {
      return;
    }

    if (state.error) {
      this.handleError(state);
      return;
    }

    if (state.refreshToken && state.token) {
      this.loginSuccessHandlerService.onSuccess(
        state.token,
        state.refreshToken,
        WebUtils.getQueryParam(this.activatedRoute, 'goto'),
        this.surveysEnabled,
      );
      return;
    }
  }

  onCredentials(state: LoginState, credentials: Credentials): void {
    this.hideError();
    this.loginService
      .login({
        ...state,
        credentials,
      })
      .pipe(takeUntil(this.endSubscription$))
      .subscribe({
        error: (error: HttpErrorResponse) => {
          this.showError(
            ErrorMessageFormatter.produceInvalidInputMessage(
              error,
              'error.invalidCredentials',
              this.defaultErrorMessage,
            ),
          );
        },
      });
  }

  handleError(state: LoginState): void {
    switch (state.error) {
      case LoginStatus.ForcedPasswordChange:
        this.forcePasswordChange(state);
        break;
      case LoginStatus.IpRestricted:
        this.showError('error.ipRestricted');
        break;
      default:
        state.error
          ? this.showError(
              this.defaultErrorMessage,
              JSON.stringify(state.error),
            )
          : this.showError(this.defaultErrorMessage);
        break;
    }
  }

  forcePasswordChange(state: LoginState): void {
    if (!state.credentials || !state.organisation || !state.token) {
      return;
    }
    this.router
      .navigate(['new-password'], {
        queryParams: {
          email: state.credentials.email,
          organisation: state.organisation.code,
          token: state.token,
        },
        relativeTo: this.activatedRoute,
      })
      .then(() => {
        this.loginService.clearState();
      });
  }

  showError(message: string, details?: string): void {
    if (!details) {
      this.error = {
        message,
      };
      return;
    }
    this.error = {
      message,
      details: `${new Date().toUTCString()} - ${
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).build
      } - ${navigator.userAgent} - ${details}`,
    };
  }

  onTwoFactor(state: LoginState, verificationCode: string): void {
    const substitution = WebUtils.getPathParam(
      this.activatedRoute,
      'substitution',
    ) as string;
    const stateWithOrganisation = {
      ...state,
      organisation: this.loginService.getOrganisation(substitution),
    };

    /* if setting up two-factor authentication for the first time, this is to inform user not to confuse
     *  authenticator secret with QR-code */
    if (
      stateWithOrganisation.twoFactor &&
      stateWithOrganisation.twoFactor.secretKey === verificationCode
    ) {
      return stateWithOrganisation.error
        ? this.showError(
            'error.codesShouldNotBeEqual',
            stateWithOrganisation.error,
          )
        : this.showError('error.codesShouldNotBeEqual');
    }

    this.loginService
      .login({
        ...stateWithOrganisation,
        twoFactor: { ...stateWithOrganisation.twoFactor, verificationCode },
      })
      .subscribe({
        error: error => {
          this.showError(
            ErrorMessageFormatter.produceInvalidInputMessage(
              error,
              'error.invalidVerificationCode',
              this.defaultErrorMessage,
            ),
          );
        },
      });
  }

  protected hideError(): void {
    this.eventAlertService.clearMessage();
    this.error = undefined;
  }

  onError(errorMessage: string): void {
    this.error = { message: errorMessage };
  }

  private readonly clearSession = (): void => {
    this.loginService.clearState();
    this.eventAlertService.setMessage({ message: 'error.sessionExpired' });
  };
}
