import { Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { translations } from '../../../translations';

export abstract class BaseInputComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  protected constructor(private readonly injector: Injector) {}
  private readonly endSubscription$ = new Subject<boolean>();
  protected focused = false;
  protected readonly inputCssClass = 'form-control new-input-styling';

  @Input() cssClass: string;
  @Input() name: string;
  @Input() id: string;
  @Input() label: string;
  @Input() placeholder = '';
  @Input() maxLength: number;
  @Input() autocomplete = true;
  @Input() autoFocus = true;
  @Input() required = false;
  @Input() readOnly = false;
  @Input() externalError: boolean;
  @Input() incorrectLabel: string;
  @Input() type: string;
  @Input() requiredIndicator = false;
  @Input() requiredLabel: string;
  @Input() invalidLabel: string;
  @Input() validateInput: (value: string | object) => boolean;

  propagateOnTouched: () => void;
  propagateOnChange: (value: string | object) => void;
  value: string | object;
  control: AbstractControl;
  translations = translations;

  ngOnInit(): void {
    const ngControl = this.injector.get(NgControl);
    if (ngControl instanceof FormControlName) {
      this.control = this.injector
        .get(FormGroupDirective)
        .getControl(ngControl);
    } else {
      this.control = (ngControl as FormControlDirective).form;
    }

    if (!this.maxLength) {
      this.maxLength = 300;
    }
  }

  ngOnDestroy(): void {
    this.endSubscription$.next(true);
    this.endSubscription$.unsubscribe();
  }

  registerOnChange(fn: typeof Function): void {
    this.propagateOnChange = fn;
  }

  registerOnTouched(fn: typeof Function): void {
    this.propagateOnTouched = fn;
  }

  writeValue(value: string | object): void {
    this.value = value;
  }

  private linkOrRegasSelectClicked(target: EventTarget | null): boolean {
    const tagName = (target as HTMLElement)?.tagName;
    const regasSelect =  (target as HTMLElement)?.classList.contains("select__value")
    return tagName === 'A' || regasSelect;
  }

  onBlur(event: FocusEvent): void {
    this.focused = false;
    if(!this.linkOrRegasSelectClicked(event.relatedTarget)){
      this.validate(this.control);
      this.propagateOnTouched();
    }
  }

  onFocus(): void {
    this.focused = true;
  }

  onChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement)?.value;
    this.writeValue(inputValue);
    this.propagateOnChange(this.value);
    this.propagateOnTouched();
    this.control.updateValueAndValidity();
  }

  isRequiredValuePresent(): boolean {
    return !!this.value;
  }

  validate(ngControl: AbstractControl): ValidationErrors | null {
    let error: ValidationErrors | null = null;
    if (this.required && !this.isRequiredValuePresent()) {
      error = { required: true };
      ngControl.setErrors(error);
      return error;
    } else if (this.control?.dirty && !this.focused) {
      if (this.validateInput && !this.validateInput(this.value)) {
        error = { invalidData: true };
        ngControl.setErrors(error);
        return error;
      }
    }
    ngControl.setErrors(null);
    return error;
  }

  getInputCssClass(): string {
    return (this.control?.touched &&
      this.control?.errors &&
      !this.control?.errors?.hiddenError) ||
      (this.externalError && this.control?.parent.pristine)
      ? `${this.inputCssClass} input-error`
      : this.inputCssClass;
  }
}
