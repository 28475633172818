import { de } from './de';
import { en } from './en';
import { fr } from './fr';
import { nl } from './nl';

export const translations = {
  en,
  'en-gb': en,
  'en-us': en,
  'en-nl': en,
  'en-be': en,
  'en-au': en,
  'en-ca': en,
  nl,
  'nl-nl': nl,
  'nl-be': nl,
  fr,
  'fr-fr': fr,
  'fr-be': fr,
  de,
  'de-de': de,
};
