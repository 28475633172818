import { Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { translations } from '../../translations';
import { ClientValidator } from '../../utils/client-validator/client-validator';
import { PasswordRecoveryService } from '../../services/recovery/password-recovery.service';
import { OrganisationNameService } from '../../services/organisation/organisation-name.service';
import { AlertMessage } from '../../models/AlertMessage';
import { EventAlertService } from '../../services/event/event-alert.service';
import { UrlResolver } from '../../utils/url-resolver/url-resolver';

@Component({
  selector: 'regas-password-change',
  templateUrl: './password-change.component.html',
})
export class PasswordChangeComponent implements OnInit, OnDestroy, DoCheck {
  private readonly endSubscription$ = new Subject<boolean>();

  translations = translations;

  formGroup = new FormGroup({
    password: new FormControl('', Validators.required),
    confirmPassword: new FormControl('', Validators.required),
  });

  email: string;
  validationCode: string;
  organisationName: string;
  substitution: string;
  token: string;
  organisationCode: string;

  loading: boolean;
  errorMessage: AlertMessage;
  exists: boolean;

  isConfirmationPasswordValid = ClientValidator.isConfirmationPasswordValid(
    this.formGroup,
  );
  isPasswordMatching = ClientValidator.notifyConfirmationPassword(
    this.formGroup,
  );

  constructor(
    private readonly passwordRecoveryService: PasswordRecoveryService,
    private readonly organisationService: OrganisationNameService,
    private readonly router: Router,
    private readonly eventMessageService: EventAlertService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const snapshot = this.activatedRoute.snapshot;

    this.substitution = snapshot.params['substitution'];
    this.validationCode = snapshot.queryParams['validationcode'];
    this.email =
      snapshot.queryParams['email'] ?? snapshot.queryParams['username'];
    this.token = snapshot.queryParams['token'];
    this.organisationCode = snapshot.queryParams['organisation'];

    this.isPresent();
  }

  ngDoCheck(): void {
    this.organisationName = this.organisationService.organisationName;
  }

  ngOnDestroy(): void {
    this.endSubscription$.next();
    this.endSubscription$.unsubscribe();
  }

  isPresent(): void {
    if (!this.token) {
      this.passwordRecoveryService
        .exists(this.email, this.validationCode, this.substitution)
        .pipe(takeUntil(this.endSubscription$))
        .subscribe({
          next: () => {
            this.exists = true;
          },
          error: (error: HttpErrorResponse) => {
            if (error.status === 404) {
              this.errorMessage = {
                message: 'passwordRecovery.passwordRecoveryDoNotExists',
              };
            } else {
              this.errorMessage = { message: 'error.unknownError' };
            }
          },
        });
    } else {
      this.exists = true;
    }
  }

  onSubmit(): void {
    this.loading = true;
    this.passwordRecoveryService
      .changePassword({
        email: this.email,
        validationCode: this.validationCode,
        password: this.formGroup.value.password,
        token: this.token,
        organisationCode: this.organisationCode,
        organisationSubstitution: this.substitution,
      })
      .pipe(takeUntil(this.endSubscription$))
      .subscribe(
        () => {
          this.eventMessageService.setMessage({
            message: 'success.passwordChange',
            type: 'success',
          });
          const route = UrlResolver.truncateLastSegment(
            this.activatedRoute.snapshot.url.map(segment => segment.path),
          );
          this.router.navigate([route]);
        },
        (errorResponse: HttpErrorResponse) => {
          this.loading = false;
          this.handleError(errorResponse);
        },
      );
  }

  private handleError(error: HttpErrorResponse): void {
    this.errorMessage = new AlertMessage();
    if (error.error?.errorCode) {
      switch (error.error.errorCode) {
        case 0:
        case 1:
          this.errorMessage.message = 'error.invalidPasswordChangeData';
          break;
        case 5:
          this.errorMessage.message = 'error.passwordSameAsPrevious';
          break;
        default:
          this.errorMessage.message = 'error.unknownError';
          break;
      }
    } else {
      this.errorMessage.message = 'error.unknownError';
    }
  }
}
