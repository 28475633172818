import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'regas-timer',
  templateUrl: './timer.component.html',
})
export class TimerComponent implements OnChanges, OnDestroy {
  @Input() time: number;
  @Output() callBack: EventEmitter<void> = new EventEmitter<void>();
  clock: ReturnType<typeof setInterval>;
  minutes = 0;
  seconds = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.time.currentValue) {
      this.startTimer();
    }
  }

  private prepareTimer(): void {
    if (this.time >= 60) {
      this.minutes = Math.floor(this.time / 60);
      this.seconds = this.time % 60;
    } else {
      this.seconds = this.time;
    }
  }

  private startTimer(): void {
    this.prepareTimer();
    this.clock = setInterval(() => {
      --this.seconds;
      if (this.seconds <= 0 && this.minutes <= 0) {
        clearInterval(this.clock);
        this.callBack.emit();
      } else if (this.seconds <= 0) {
        --this.minutes;
        this.seconds = 59;
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.clock);
  }
}
