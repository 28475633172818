import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[regasAutofocusDirective]',
})
export class RegasAutofocusDirective implements AfterViewInit {
  @Input() regasAutofocusDirective = true;

  constructor(private readonly el: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.regasAutofocusDirective) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, 500);
    }
  }
}
