import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OriginService } from '@regas/shared';
import { BaseLogin } from '../base-login';
import { LocalStorageKeys } from '../../utils/storage-manager/local-storage-keys';
import { LoginState } from '../../models/interface/login-state.interface';
import { LoginStage } from '../LoginStage';
import { EventAlertService } from '../../services/event/event-alert.service';
import { SessionTimerService } from '../../services/session/session-timer.service';
import { LoginService } from '../../services/login/login.service';
import { LoginStageResolver } from '../../utils/state-resolver/login-stage-resolver';
import { AlertMessage } from '../../models/AlertMessage';
import { SsoErrorService } from '../../services/sso/sso-error/sso-error.service';
import { LoginSuccessHandlerService } from "../../services/login/success/login-success-handler.service";
import { LoginTranslatePipe } from "../../pipes/login-translate.pipe";
import { UserAuthInfo } from "../../services/login/UserAuthInfo";

@Component({
  selector: 'regas-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['user-login.component.scss'],
})
export class UserLoginComponent extends BaseLogin implements OnInit {
  currentLoginStage = LoginStage.CREDENTIALS;

  constructor(
    @Inject(DOCUMENT) protected readonly document: Document,
    protected readonly eventAlertService: EventAlertService,
    protected readonly sessionTimerService: SessionTimerService,
    protected readonly httpClient: HttpClient,
    protected readonly loginService: LoginService,
    protected readonly originService: OriginService,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly router: Router,
    changeDetectorRef: ChangeDetectorRef,
    loginSuccessHandlerService: LoginSuccessHandlerService,
    private readonly translatePipe: LoginTranslatePipe,
    private readonly ssoErrorService: SsoErrorService
  ) {
    super(
      eventAlertService,
      sessionTimerService,
      document,
      httpClient,
      loginService,
      originService,
      activatedRoute,
      router,
      changeDetectorRef,
      loginSuccessHandlerService
    );
  }

  protected doBeforeInit(): void {

    const existingUserType = localStorage.getItem(LocalStorageKeys.NON_CLIENT_USER)
    if (!existingUserType) {
      console.warn("Clearing any existing client user session.")
      this.loginService.clearUserAuthInfo()
    }

    localStorage.setItem(LocalStorageKeys.NON_CLIENT_USER, 'true');
  }

  protected doAfterInit(): void {
    this.handleSsoMessage();
  }

  private handleSsoMessage(): void {
    const ssoError = this.ssoErrorService.readAndDelete();
    if (ssoError) {
      const message: AlertMessage = {
        message: this.translatePipe.transform(
          ssoError.message,
          this.translations,
          { incidentId: ssoError.incidentId }
        ),
        type: 'danger',
      };
      this.eventAlertService.setMessage(message);
    }
  }

  protected handleAttempt(state: LoginState, userAuthInfo: UserAuthInfo): void {
    this.currentLoginStage = LoginStageResolver.resolve(state);
    super.handleAttempt(state, userAuthInfo);
  }

  displayLanguageSelector(): boolean {
    return (
      LoginStage.CREDENTIALS === this.currentLoginStage ||
      LoginStage.AUTHENTICATED === this.currentLoginStage
    );
  }

  displayCustomHeader(): boolean {
    return (
      LoginStage.TWO_FACTOR_CONFIGURATION === this.currentLoginStage ||
      LoginStage.TWO_FACTOR === this.currentLoginStage ||
      LoginStage.TWO_FACTOR_AUTHENTICATED === this.currentLoginStage
    );
  }

  displayCredentials(): boolean {
    return (
      LoginStage.CREDENTIALS === this.currentLoginStage ||
      LoginStage.AUTHENTICATED === this.currentLoginStage
    );
  }

  displayTwoFactor(): boolean {
    return (
      LoginStage.TWO_FACTOR === this.currentLoginStage ||
      LoginStage.TWO_FACTOR_AUTHENTICATED === this.currentLoginStage ||
      LoginStage.TWO_FACTOR_CONFIGURATION === this.currentLoginStage
    );
  }
}
