import { logInVerb } from './log-in-verb';
import { password } from './password';
import { passwordRequired } from './passwordRequired';
import { usernameRequired } from './usernameRequired';
import { username } from './username';
import { code } from './code';

export const fr = {
  codeRequired: 'Entrez le code requis',
  fixedLength: `le ${code.fr} doit comporter 6 chiffres`,
  company: 'myneva',
  email: "L'adresse e-mail",
  emailRequired: "L'adresse email est requis",
  enterTheAuthenticatorCode:
    "Entrez le code fourni par l'application d'authentification",
  enterTheSmsCode: 'Entrez le code envoyé à',
  error: {
    attemptsLeft1: 'Seulement 1 tentative de plus disponible.',
    attemptsLeft2: '2 tentatives supplémentaires disponibles.',
    invalidCredentials: 'Adresse e-mail et/ou mot de passe invalide.',
    invalidPasswordChangeData:
      "La demande de changement de mot de passe a expiré ou n'est pas valide.",
    ipRestricted:
      "Il n'y a pas d'accès à cette organisation à partir de cette adresse IP.",
    invalidVerificationCode: 'Code de vérification invalide.',
    login: "La connexion n'est pas possible en ce moment.",
    maximumReached:
      'Nombre maximal de tentatives atteint. Réessayer dans quelques minutes.',
    noCookieSet:
      'Les cookies ne sont pas activés sur votre navigateur. Veuillez les activer dans les préférences de votre navigateur pour continuer.',
    passwordSameAsPrevious:
      'Le mot de passe est le même que le mot de passe précédent.',
    sms: {
      exceeded:
        'Le nombre maximum de SMS envoyés est atteint. Au bout de 10 minutes, vous pourrez renvoyer le SMS.',
      unexpected: "Une erreur inattendue s'est produite. Veuillez réessayer.",
    },
    sessionExpired: 'La session a expiré. Veuillez réessayer.',
    unknownError: "Une erreur inattendue s'est produite. Veuillez réessayer.",
    sso: {
      loginFailed:
        "Une erreur SSO s'est produite.<br>Incident id: {{incidentId}}.",
    },
  },
  incorrectCredentials: 'Adresse e-mail et/ou mot de passe invalide',
  invalidEmailFormat: "Le format de l'adresse e-mail est invalide",
  forgotPassword: 'Mot de passe oublié?',
  logIn: logInVerb.fr,
  maximumReachedTwoFactor:
    'Nombre maximum de tentatives atteint. Contactez votre organisation.',
  missingPhoneInfo:
    'Pour continuer, veuillez nous fournir votre numéro de téléphone',
  next: 'SUIVANT',
  notFoundPage: {
    invitation: {
      header: 'The invitation has expired',
      content:
        'If you are not able to find the problem, please contact your application manager',
    },
    loginMigration: {
      header: 'We have moved the login page!',
      content:
        'The login flow is now specific for your organisation.<br>Please contact your application manager(s) of your organisation for support.',
    },
    header: 'This is not the correct URL',
    content:
      'Please check the URL.<br>If you are not able to find the problem, please contact your application manager.',
  },
  loadingPage: {
    loading: 'Loading',
  },
  password: password.fr,
  passwordRecovery: {
    forcePasswordChange:
      "L'organisation a été configurée pour changer périodiquement le mot de passe doit être. Vous devez maintenant changer le mot de passe",
    passwordRecoveryDoNotExists:
      "Le lien de récupération du mot de passe n'a pas été trouvé.",
    resetYourPassword: 'Réinitialisez votre mot de passe',
    requestPasswordChangeBottomText:
      'Entrez votre nouveau mot de passe ci-dessous',
    requestPasswordChangeButtonText: 'Réinitialiser le mot de passe',
    requestWasSentUpperText: 'La demande a été envoyée',
    requestWasSentBottomText: "Suivez les instructions dans l'e-mail",
    resetPasswordRequestBottomText:
      'Vous recevrez un e-mail avec des instructions',
  },
  passwordRequired: passwordRequired.fr,
  policy: 'Politique de confidentialité',
  registration: {
    button: "S'inscrire",
    error: {
      codeExpiredOrNotFound: "L'invitation a expiré",
      emailNotUnique: "L'adresse e-mail est en cours d'utilisation",
      invalid: {
        code: 'Code de vérification invalide',
      },
      incorrectPhoneNumber: 'Le numéro de téléphone est incorrect.',
      passwordRequired: 'Mot de passe requis',
      passwordsDontMatch: 'Les mots de passe ne correspondent pas',
      passwordValidationError: {
        uppercaseRequired: 'Faible - au moins une lettre majuscule est requise',
        lowercaseRequired: 'Faible - au moins une lettre minuscule est requise',
        numberRequired: 'Faible - au moins un chiffre est requis',
        minRequired: 'Faible - le mot de passe est trop court',
      },
      phoneNumberInvalidFormat: 'Format de numéro de téléphone invalide',
      phoneNumberRequired: 'Le numéro de téléphone est requis',
      confirmPasswordRequired: 'La confirmation du mot de passe est requise',
      unexpected: "Une erreur inattendue s'est produite. Veuillez réessayer",
    },
    info: 'Créer un compte pour',
    label: {
      phoneNumber: 'Numéro de portable',
      password: 'Créer un mot de passe',
      confirmPassword: 'Confirmez le mot de passe',
    },
    password: {
      strong: 'Forte',
      good: 'Bon',
      fair: 'Suffisant',
    },
    placeholder: {
      phoneNumber: 'Entrez votre numéro de téléphone',
      password: 'Tapez votre mot de passe',
      confirmPassword: 'Entrez à nouveau votre mot de passe',
    },
  },
  twoFactorAuthenticationQrCode: {
    setupAuthentication:
      "Le gestionnaire d'application a rendu l'authentification à deux facteurs obligatoire pour se connecter",
    scanQR:
      "Scan QR-code dans l' authentificateur, ou veuillez entrer code ci-dessus manuellement",
  },
  sendNew: 'ENVOYER UN NOUVEAU CODE',
  sendNewCodeTimerInfo: 'Envoyez un nouveau code en: ',
  smsAttemptsExceeded:
    " Vous n'avez pas reçu le code? Contactez votre organisation",
  sso: {
    or: 'OU',
    placeholder: 'Sélectionner une option',
    login: 'SSO Login',
  },
  success: {
    passwordChange: 'Le mot de passe a été changé avec succès.',
    registrationCompleted: 'Inscription terminée avec succès.',
  },
  terms: "Conditions d'utilisation",
  title: 'Connecter à myneva',
  typeYourEmail: 'Entrez votre adresse e-mail',
  typeYourPassword: 'Tapez votre mot de passe',
  username: username.fr,
  usernameRequired: usernameRequired.fr,
  surveyRequestDialog: {
    infoContent:
      'En répondant à l’enquête, vous serez redirigé vers une nouvelle page.',
    doNotPresent: 'Ne me le rappelez plus',
    cancel: 'Pas maintenant',
    submit: 'Répondre à l’enquête',
    popupsBlockedAlert:
      "Votre navigateur bloque l'ouverture d'un onglet supplémentaire. Veuillez faire confiance à {{ url }} dans les paramètres de votre navigateur.",
  },
};
