import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { OriginService } from '@regas/shared';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LoginState } from '../../models/interface/login-state.interface';
import { BaseLogin } from '../base-login';
import { Phone } from '../../models/Phone';
import { SmsService } from '../../services/sms/sms.service';
import { EventAlertService } from '../../services/event/event-alert.service';
import { SessionTimerService } from '../../services/session/session-timer.service';
import { Credentials } from '../../models/interface/credentials.interface';
import { LoginService } from '../../services/login/login.service';
import { LoginSuccessHandlerService } from '../../services/login/success/login-success-handler.service';
import { LocalStorageKeys } from '../../utils/storage-manager/local-storage-keys';

@Component({
  selector: 'regas-client-login',
  templateUrl: './client-login.component.html',
})
export class ClientLoginComponent extends BaseLogin
  implements OnInit, OnDestroy {
  constructor(
    private readonly smsService: SmsService,
    @Inject(DOCUMENT) protected readonly document: Document,
    protected readonly eventAlertService: EventAlertService,
    protected readonly sessionTimerService: SessionTimerService,
    protected readonly httpClient: HttpClient,
    protected readonly loginService: LoginService,
    protected readonly originService: OriginService,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly router: Router,
    changeDetectorRef: ChangeDetectorRef,
    loginSuccessHandlerService: LoginSuccessHandlerService,
  ) {
    super(
      eventAlertService,
      sessionTimerService,
      document,
      httpClient,
      loginService,
      originService,
      activatedRoute,
      router,
      changeDetectorRef,
      loginSuccessHandlerService,
    );
    this.surveysEnabled = false;
  }

  protected doBeforeInit(): void {
    const existingUserType = localStorage.getItem(
      LocalStorageKeys.NON_CLIENT_USER,
    );
    if (existingUserType) {
      console.warn('Clearing existing non client user type session.');
      this.loginService.clearUserAuthInfo();
    }
    localStorage.removeItem(LocalStorageKeys.NON_CLIENT_USER);
  }

  protected doAfterInit(): void {}

  onCredentials(state: LoginState, credentials: Credentials): void {
    super.onCredentials(state, credentials);
  }

  onPhoneNumberProvided(state: LoginState, phone: Phone): void {
    state.phone = phone;
    if (state.token) {
      this.smsService.sendSmsCode(state.token, state.phone).subscribe();
    }
  }
}
