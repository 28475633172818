<div class="language-selector selectable-lng-box" *ngIf="selectedOption">
  <div
    class="option selectable-lng-box"
    [id]="selectedOption.abbreviation + '-' + selectedOption.name"
  >
    <div class="icon-wrapper">
      <img
        class="flag-icon selectable-lng-box"
        [src]="selectedOption.path"
        [alt]="selectedOption.path"
      />
      <div class="language-name selectable-lng-box">
        {{ selectedOption.name }}
      </div>
    </div>
    <div class="angle-wrapper">
      <div class="angle-sub-wrapper">
        <img
          *ngIf="!displayOptions"
          class="angle selectable-lng-box"
          src="assets/icons/client-user/small-icon/angle-down.svg"
          alt="angle-down"
        />
        <img
          *ngIf="displayOptions"
          class="angle selectable-lng-box"
          src="assets/icons/client-user/small-icon/angle-up.svg"
          alt="angle-up"
        />
      </div>
    </div>
  </div>
  <div class="options" *ngIf="displayOptions">
    <div
      class="option-to-select"
      *ngFor="let languageOption of getAvailableOptions()"
      [id]="languageOption.abbreviation + '-' + languageOption.name"
      (click)="setOption(languageOption)"
    >
      <div class="option-content">
        <div class="icon-wrapper">
          <img
            class="flag-icon"
            [src]="languageOption.path"
            [alt]="languageOption.path"
          />
        </div>
        <div class="option-name">{{ languageOption.name }}</div>
      </div>
    </div>
  </div>
</div>
