import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subdomain } from '@regas/bruce';
import { OriginService } from '@regas/shared';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SsoIntegrationDto } from '../../../models/SsoIntegrationDto';
import { SsoIntegrationFactory } from '../../../login/sso-integration/sso-integration-factory';
import { SsoIntegration } from '../../../models/SsoIntegration';
import { SsoUserGroup } from "../../../models/SsoUserGroup";

@Injectable({ providedIn: 'root' })
export class SsoIntegrationService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly originService: OriginService,
  ) {}

  public getSsoIntegrations(
    organisationSubstitution: string,
    userGroup: SsoUserGroup,
    goto: string | null,
  ): Observable<SsoIntegration[]> {
    const apiUrl = this.originService.getUrl(Subdomain.Api, 'saml/login')
      .href;

    return this.httpClient
      .get<SsoIntegrationDto[]>(
        this.originService.getUrl(Subdomain.Api, 'sso-integrations').href,
        {
          params: new HttpParams()
              .set('substitution', organisationSubstitution)
              .set('userGroup', userGroup)
        },
      )
      .pipe(
        map((ssoIntegrations: SsoIntegrationDto[]) =>
          SsoIntegrationFactory.map(
            ssoIntegrations,
            apiUrl,
            organisationSubstitution,
            goto,
          ),
        ),
      );
  }
}
