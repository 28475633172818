<div class="code-placeholder">
  <form [formGroup]="formGroup">
    <div class="code-container">
      <div
        class="number-container"
        *ngFor="let c of [].constructor(2); let container = index"
      >
        <ng-container *ngFor="let n of [].constructor(3); let number = index">
          <div [class]="number !== 1 ? 'number' : 'middle-number'">
            <input
              [formControlName]="resolveInputId(number, container)"
              [id]="resolveInputId(number, container)"
              maxlength="1"
              (input)="onInput($event)"
              (keydown)="onKeyPressed($event)"
              (click)="onClick($event)"
              #codeNumber
              regasCodeNumber
              autocomplete="off"
              [class]="getInputCssClass(resolveInputId(number, container))"
              type="tel"
            />
          </div>
        </ng-container>
        <div *ngIf="container == 0" class="container-separator"></div>
      </div>
      <div class="error-message">
        <regas-input-error *ngIf="isCodeRequired()">
          {{ 'codeRequired' | translate: translations | upperFirst }}
        </regas-input-error>
        <regas-input-error *ngIf="isCodeNotNumber()">
          {{ 'fixedLength' | translate: translations | upperFirst }}
        </regas-input-error>
        <regas-input-error *ngIf="error && this.formGroup.pristine">
          {{ error.message | translate: translations | upperFirst }}
        </regas-input-error>
      </div>
    </div>
  </form>
</div>
