<div class="two-factor-auth-container">
  <regas-google-two-factor-authentication-header
    *ngIf="state.twoFactorType === 'GOOGLE_AUTHENTICATOR'"
    [configuration]="googleConfiguration"
  >
  </regas-google-two-factor-authentication-header>
  <regas-sms-two-factor-authentication-header
    *ngIf="state.twoFactorType === 'SMS_CODE'"
    [configuration]="smsConfiguration"
    (errorEvent)="onError($event)"
  >
  </regas-sms-two-factor-authentication-header>
  <regas-client-alert [error]="getAlertError()"> </regas-client-alert>
  <form [formGroup]="formGroup">
    <regas-authentication-code
      formControlName="authenticationCode"
      [error]="getAuthenticationCodeError()"
    >
    </regas-authentication-code>
    <div class="form-input-wrapper button-placeholder text-center">
      <regas-button
        [loading]="state?.loading || loading"
        [formGroup]="formGroup"
        buttonText="{{ 'next' | translate: translations }}"
        (submitForm)="onSubmit()"
        (errorEvent)="authenticationCodeComponent.markAsInvalid()"
      >
      </regas-button>
    </div>
  </form>
</div>
