import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
import { LoginTranslatePipe } from '../pipes/login-translate.pipe';
import { LanguageSelectorComponent } from './selectors/language-selector/language-selector.component';
import { ClientAlertComponent } from './client-alert/client-alert.component';
import { TimerComponent } from './timer/timer.component';
import { ButtonComponent } from './inputs/buttons/button.component';
import { PhonePrefixSelectorComponent } from './selectors/phone-prefix-selector/phone-prefix-selector.component';
import { InputModule } from './inputs/input.module';
import { PasswordInputComponent } from './inputs/inputs/password-input/password-input.component';
import { ClientPhoneInputComponent } from './inputs/inputs/client-phone-input/client-phone-input.component';
import { TextInputComponent } from './inputs/inputs/text-input/text-input.component';
import { SelectorModule } from './selectors/selector.module';
import { InputErrorComponent } from './inputs/inputs/text-input/input-error/input-error.component';
import { ClientAlertModule } from './client-alert/client-alert.module';
import { LoadingPageComponent } from './pages/loading-page/loading-page.component';
import { LoadingPageModule } from './pages/loading-page/loading-page.module';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { NotFoundPageModule } from './pages/not-found-page/not-found-page.module';

@NgModule({
  imports: [
    PipesModule,
    InputModule,
    SelectorModule,
    ClientAlertModule,
    CommonModule,
    LoadingPageModule,
    NotFoundPageModule,
  ],
  declarations: [TimerComponent],
  exports: [
    LanguageSelectorComponent,
    ClientAlertComponent,
    TimerComponent,
    ButtonComponent,
    PhonePrefixSelectorComponent,
    PasswordInputComponent,
    ClientPhoneInputComponent,
    TextInputComponent,
    InputErrorComponent,
    LoadingPageComponent,
    NotFoundPageComponent,
  ],
  providers: [LoginTranslatePipe],
})
export class ComponentsModule {}
